import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "../Firebase";
import Footer from "../Footer";
import Nav from "../Nav";
import { motion } from "framer-motion";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import danceMode from "../user/images/Dancemode.gif";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { Helmet } from "react-helmet";
function Dance() {
  const [images, setImages] = useState([]);
  const [actingPackages, setActingPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    course: "select course",
  });
  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Form Data"), formData);
      // Success! Data is saved to Firestore.
      toast("Form data saved ");
      // Reset the form
      setFormData({
        name: "",
        email: "",
        number: "",
        course: "select course",
      });
    } catch (error) {
      // Handle error
      toast("Error saving form data: ", error);
    }
  };
  useEffect(() => {
    const fetchActingPackages = async () => {
      try {
        const packagesRef = collection(db, "packages");
        const q = query(packagesRef, where("course", "==", "Dancing"));
        const querySnapshot = await getDocs(q);

        const actingPackageData = querySnapshot.docs.map((doc) => doc.data());
        const sortedActingPackages = actingPackageData.sort(
          (a, b) => a.amount - b.amount
        );
        setActingPackages(sortedActingPackages);
        setLoading(false);
      } catch (error) {
        toast("Error fetching  packages:", error);
        setError("Error fetching  packages");
        setLoading(false);
      }
    };

    fetchActingPackages();
    const fetchImages = async () => {
      const storage = getStorage();
      const coursesRef = ref(storage, "courses");
      const actingRef = ref(coursesRef, "Dance");

      try {
        const imageList = await listAll(actingRef);
        const imageURLs = await Promise.all(
          imageList.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            // console.log(downloadURL);
            return downloadURL;
          })
        );
        setImages(imageURLs);
        // console.log(imageURLs);
      } catch (error) {
        toast("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const benefitsList = [
    "Body awareness",
    "Increased stamina",
    "Physical flexibility and strength",
    "Emotional balance",
    "Stress reduction",
    "Proprioceptive sensitivity",
    "Deeper focus",
    "Calms nerves and anxiety",
    "Increased creativity",
    "Confidence",
    "Endurance and resilience (physical and mental)",
    "Overall well-being",
  ];

  return (
    <div>
      <Nav />
      <Helmet>
        {/* <title>Diploma in Acting in Ahmadabad | Complete Acting Course</title> */}
        <meta
          name="description"
          content="The KK Institute is the best dance classes in ahmedabad, offering expert training in various dance forms. Join us for a dance journey!"
        />
      </Helmet>
      <section className="relative h-screen overflow-hidden">
        {images.map((imageUrl, index) => (
          <motion.img
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={imageUrl}
            alt={`Image ${index}`}
          />
        ))}
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div className="container mx-auto h-full flex items-center justify-center">
          <motion.div
            initial={{ opacity: 1, y: -20 }}
            animate={{ opacity: 1, y: 20 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="text-center text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              Dance Dreams Come Alive
            </h1>
            <p className="text-lg md:text-2xl mb-6">
              Where Passion Meets Movement
            </p>
          </motion.div>
        </div>
      </section>
      <h2 className="my-5 text-center text-5xl font-bold">
        <span className="text-red-500">Best</span> Dance Classes In Ahmedabad
      </h2>
      <section className="bg-white ">
        <div className="container mx-auto flex flex-wrap items-center">
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-2/3 mb-8 md:mb-0"
          >
            <div className="px-8">
              <h2 className="text-4xl font-bold mb-4">
                Why integrate Dance into acting class?
              </h2>
              <p className="text-gray-600 mb-8">
                THE KK INSTITUTE is the best dance classes in ahmedabad. As a
                professional actor, you are always on the move. Regularly
                attending auditions, actively participating in physical
                performances on stage and set and frequently indulging in the
                art of dance – all integral aspects of an actor's thriving
                career
              </p>
              <p className="text-gray-600 mb-8">
                THE KK INSTITUTE is the top dance classes in ahmedabad-Gujarat.
                Even if you don't label yourself as a "dancer," the likelihood
                is high that your career will demand some degree of movement and
                dancing skills. As an actor, it's crucial to relish every facet
                of your journey in the acting world and among these aspects is
                the ability to dance. You need not aspire to become the next
                Hritik Roshan or Michael Jackson, but there are numerous
                benefits to possessing dance skills that extend well beyond the
                realm of dancing itself. Taking dance classes helps you to be
                more mentally alert, more focused and less tense. This ability
                can also assist you in improvising effectively during auditions,
                aiding in managing both mental and physical stress.
              </p>
              <p className="text-gray-600 mb-8">
                As the best dance academy in ahmedabad, we offer courses that
                integrate dance into acting training, it could be a great
                opportunity for individuals looking to pursue a comprehensive
                performing arts education.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3 px-8"
          >
            <form onSubmit={handleSubmit}>
              {/* <!-- component --> */}
              <div className="min-h-screen  py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                  <div className="absolute inset-0 bg-gradient-to-r from-red-300 to-red-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                  <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                      <div>
                        <h2 className="text-2xl font-semibold">Enroll Now</h2>
                      </div>
                      <div className="divide-y divide-gray-200">
                        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="name"
                            />
                            <label
                              htmlFor="name"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Name
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Email address"
                            />
                            <label
                              htmlFor="email"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Email Address
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="number"
                              name="number"
                              type="number"
                              value={formData.number}
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Password"
                            />
                            <label
                              htmlFor="number"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Phone
                            </label>
                          </div>
                          <div className="relative">
                            <select
                              value={formData.course}
                              name="course"
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-600 focus:outline-none focus:borer-rose-600"
                            >
                              <option>Select Course</option>
                              <option>Complete Acting Course</option>
                              <option>Acting</option>
                              <option>Film Acting Course</option>
                              <option>Child Acting</option>
                              <option>Mix Martial Arts</option>
                              <option>Photoshoot</option>
                              <option>Dancing</option>
                              <option>Modelling</option>
                              <option>Grooming</option>
                              <option>Singing</option>
                              <option>Yoga</option>
                              <option>Zumba</option>
                            </select>
                            {/* <label htmlFor="number" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">phone</label> */}
                          </div>

                          <div className="relative">
                            <button className="bg-red-500 text-white rounded-md px-2 py-1">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </section>

      <div class="container my-24 px-6 mx-auto">
        <h2 className="text-2xl font-bold mb-6   text-center">
          Benefits of Dancing
        </h2>
        <section class="mb-32 text-gray-800">
          {/* <h2 class="text-3xl font-bold mb-12 text-center">Projects we are proud of</h2> */}

          <div class="flex flex-wrap items-center">
            <div class="grow-0 shrink-0 basis-auto w-full lg:w-5/12 mb-12 lg:mb-0 md:px-6">
              <div
                class="relative overflow-hidden bg-no-repeat bg-cover rounded-lg shadow-lg"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
              >
                <img src={danceMode} class="w-full" />
                <a href="#!">
                  <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed">
                    <div class="flex justify-center items-center h-full">
                      <div class="px-6 py-12 md:px-12 text-white text-center">
                        {/* <h3 class="text-2xl uppercase font-bold mb-6">
                          Benefits of Dancing
                        </h3> */}
                        {/* <p >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quia
                  laboriosam error consequuntur fugit, doloribus rerum, iure nesciunt amet
                  quidem veniam cupiditate hic fugiat dolore aperiam quisquam libero earum
                  quibusdam?
                </p> */}
                      </div>
                    </div>
                  </div>
                  <div class="relative overflow-hidden bg-no-repeat bg-cover">
                    <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-100 transition duration-300 ease-in-out"></div>
                  </div>
                </a>
              </div>
            </div>

            <div class="grow-0 shrink-0 basis-auto w-full lg:w-7/12 md:px-6">
              <div class="flex mb-12">
                <div class="shrink-0">
                  <div class="p-4 rounded-md shadow-lg">
                    <svg
                      class="w-5 h-5 text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="grow ml-4">
                  <p class="font-bold mb-1">
                    {" "}
                    Enhanced mental alertness simplifies the process of
                    receiving and making decisions:
                  </p>
                  <p class="text-gray-500">
                    Dancing demands mental concentration and coordination,
                    contributing to the enhancement of cognitive abilities and
                    decision-making aptitude.
                  </p>
                </div>
              </div>

              <div class="flex mb-12">
                <div class="shrink-0">
                  <div class="p-4 rounded-md shadow-lg">
                    <svg
                      class="w-5 h-5 text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="grow ml-4">
                  <p class="font-bold mb-1">
                    {" "}
                    Participating in a dance class will both mentally and
                    physically relax you
                  </p>
                  <p class="text-gray-500">
                    Dancing can help release tension, stress and stiffness in
                    both the mind and body, promoting relaxation and well-being.
                  </p>
                </div>
              </div>

              <div class="flex mb-12">
                <div class="shrink-0">
                  <div class="p-4 rounded-md shadow-lg">
                    <svg
                      class="w-5 h-5 text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path
                        fill="currentColor"
                        d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="grow ml-4">
                  <p class="font-bold mb-1">
                    {" "}
                    Physical confidence is a product of a robust, flexible and
                    stress-free body.
                  </p>
                  <p class="text-gray-500">
                    Dancing involves a wide range of movements that can improve
                    strength, flexibility and body awareness, enhancing physical
                    confidence and self-esteem.
                  </p>
                </div>
              </div>

              <div class="flex">
                <div class="shrink-0">
                  <div class="p-4 rounded-md shadow-lg">
                    <svg
                      class="w-5 h-5 text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 544 512"
                    >
                      <path
                        fill="currentColor"
                        d="M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="grow ml-4">
                  <p class="font-bold mb-1">Helps your mind to focus.</p>
                  <p class="text-gray-500">
                    Dancing requires concentration and focus, which can improve
                    cognitive skills such as attention and memory.
                  </p>
                </div>
              </div>
              <div class="flex mt-10">
                <div class="shrink-0">
                  <div class="p-4 rounded-md shadow-lg">
                    <svg
                      class="w-5 h-5 text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 544 512"
                    >
                      <path
                        fill="currentColor"
                        d="M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="grow ml-4">
                  <p class="font-bold mb-1"> Dancing builds strength.</p>
                  <p class="text-gray-500">
                    Dancing encompasses a multitude of movements that can sculpt
                    and fortify muscles, fostering physical fitness and
                    endurance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="h-full mb-20 flex flex-col justify-center items-center px-4 lg:px-0">
        <div className="w-full max-w-3xl mx-auto">
          <div className="text-center font-semibold">
            <h2 className="text-3xl md:text-5xl">
              <span className="text-red-700 tracking-wide">Dance </span>
              <span>packages</span>
            </h2>
            <p className="pt-4 md:pt-6 text-base md:text-xl text-gray-400 font-normal">
              Choose a plan that works best for you
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between pt-8 md:pt-24">
            {actingPackages.map((p) => (
              <div
                key={p.packageType}
                className={`w-full md:w-1/3 p-4 md:p-8 even:bg-black even:text-white text-center rounded-3xl shadow-xl`}
              >
                <h2 className="text-lg md:text-2xl font-semibold">
                  {p.packageType}
                </h2>
                <p className="pt-2 tracking-wide">
                  <span className="text-gray-400 align-top">₹ </span>
                  <span className="text-xl md:text-3xl font-semibold">
                    {p.amount}
                  </span>
                </p>
                <hr className="mt-4 border-1" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default Dance;
