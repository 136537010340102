import { useEffect, useState } from 'react';
import { collection, query, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../Firebase';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';

function GalleryDelete() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const imagesRef = collection(db, 'Gallery');
    const q = query(imagesRef);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const imageList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setImages(imageList);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleDelete = async (imageId) => {
    try {
      await deleteDoc(doc(db, 'Gallery', imageId));
      toast('Image deleted successfully');
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  return (
    <div>
      
      <h1 className="my-5 text-center text-3xl font-bold">
        <span className='text-red-500'>Event</span> Gallery
      </h1>
      <motion.div
        className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.1 }}
      >
        {images.map((image) => (
          <motion.div
            key={image.id}
            className="bg-white rounded-lg p-4 shadow-md"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <img src={image.imageUrl} alt={image.description} className="w-full h-auto" />
            <h3 className="mt-2 text-lg font-semibold">Description:</h3>
            <p className="mt-1 text-gray-800">{image.description}</p>
            <a
              href={image.youtubeLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-red-500 text-center hover:underline mt-2 block"
            >
              Watch Video
            </a>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded mt-2"
              onClick={() => handleDelete(image.id)}
            >
              Delete
            </button>
          </motion.div>
        ))}
      </motion.div>
      <ToastContainer />
    </div>
  );
}

export default GalleryDelete;
