import React from "react";
// import NavBar from "../NavBar";
import SliderComponent from "../SliderComponent";
import { motion } from "framer-motion";
import ticket from "./images/ticket.png";
// import lifetime from "./images/Lifetime.gif";
// import hero from "./images/hero.png";
// import heroImg from "./images/hero-course.png";
// import ting from "./images/ting.png";
// import {motion} from 'framer-motion'
// import WELCOME from "./images/WELCOME.mp4";
// import keypoints from "./images/keypoints.gif";
import line from "./images/line.png";
import acting from "./images/Acting.png";
import completeacting from "./images/completeacting.png";
import dance from "./images/dance.png";
import grooming from "./images/grooming.png";
import modelling from "./images/modelling.png";
import photoshoot from "./images/photoshoot.png";
import singing from "./images/singing.png";
import yoga from "./images/yoga.png";
import zumba from "./images/zumba.png";
import mma from "./images/mma.png";
import childacting from "./images/childacting.png";
import filmacting from "./images/filmacting.png";
import best from "./images/best.png";
import knowledge from "./images/knowledge.png";
import advanced from "./images/advanced.png";
import challenge from "./images/challenge.png";
import no from "./images/no.png";
// import keypoints from "./images/keyPoints.png";
import Footer from "../Footer";
// import ResponsiveVideo from 'react-responsive-video';
import { useTransform, useScroll } from "framer-motion";
import Nav from "../Nav";

function Home() {
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], ["100vh", "0"]);
  return (
    <motion.div style={{ y }} className="bg-white">
      {/* <NavBar /> */}
      <Nav />
      <SliderComponent />

      <div className=" py-10 px-4 md:px-12 lg:px-24">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="bg-white rounded-lg shadow-2xl p-8 md:p-12 lg:p-16"
        >
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold text-gray-800 mb-4 text-justify">
            Welcome to the Best Acting Institute of Ahmedabad
          </h1>
          <p className="text-lg text-gray-600">
            If you are looking for the best acting institute in
            ahmedabad-gujarat, then look no further. THE KK INSTITUTE is a
            leading acting school in ahmedabad. We provide structured courses
            designed to teach individuals the skills and techniques required to
            become proficient actors. We offer it at various levels, from
            beginners to advanced and cater to individuals of all ages who have
            an interest in pursuing a career in acting or simply want to improve
            their acting skills as a hobby. THE KK INSTITUTE is widely regarded
            as the premier acting institute in ahmedabad, known for its
            excellence in nurturing and honing the talents of aspiring actors.
            With a strong focus on practical training and industry-relevant
            curriculum, the institute has produced numerous successful actors
            who have made their mark in the entertainment world. It boasts
            state-of-the-art facilities, experienced faculty and a supportive
            learning environment that empowers students to explore their
            creativity and develop their acting skills to the fullest. The KK
            Institute's commitment to quality education and its impressive track
            record make it the top choice for those seeking a promising career
            in acting in Ahmedabad.
          </p>
        </motion.div>
      </div>

      <section className="bg-white">
        <div className="container px-6 py-12 mx-auto">
          <div className="text-center">
            {/* <p className="font-medium text-red-500 text-4xl dark:text-red-700">4</p> */}

            <section className="bg-white ">
              <div className="container px-6 py-10 mx-auto">
                <div className="text-center">
                  <h2 className="text-2xl font-semibold capitalize lg:text-3xl">
                    Key Points
                  </h2>
                  <div className="mt-2">
                    <span className="inline-block w-40 h-1 bg-red-700 rounded-full"></span>
                    <span className="inline-block w-3 h-1 ml-1 bg-red-700 rounded-full"></span>
                    <span className="inline-block w-1 h-1 ml-1 bg-red-700 rounded-full"></span>
                  </div>
                </div>
              </div>
            </section>

            {/* <p className="mt-3 text-gray-500 dark:text-gray-400">Our friendly team is always here to chat.</p> */}
          </div>

          <div className="grid grid-cols-1 gap-12 mt-10 md:grid-cols-2 lg:grid-cols-4">
            <div className="flex flex-col items-center justify-center text-center ">
              <span className="p-3 text-red-700 rounded-full  ">
                <img src={best} />
              </span>

              {/* <h2 className="mt-4 text-lg font-medium text-gray-800 dark:text-white">Office</h2> */}
              <p className="mt-2 ">
                THE KK INSTITUTE is the best place to turn your passion into
                reality.The best institute for ACHIEVING DREAMS
              </p>
              {/* <p className="mt-2 text-red-700 dark:text-blue-400">100 Smith Street Collingwood VIC 3066 AU</p> */}
            </div>

            <div className="flex flex-col items-center justify-center text-center">
              <span className="p-3 text-red-700 rounded-full pt-6 ">
                <img src={knowledge} />
              </span>

              {/* <h2 className="mt-4 text-lg font-medium text-gray-800 dark:text-white">Office</h2> */}
              <p className="mt-2 ">
                The courses offered at THE KK INSTITUTE not only help
                individuals learn acting but also gives a complete knowledge
                about the film industry and film production
              </p>
              {/* <p className="mt-2 text-red-700 dark:text-blue-400">100 Smith Street Collingwood VIC 3066 AU</p> */}
            </div>

            <div className="flex flex-col items-center justify-center text-center">
              <span className="p-3 text-red-700 rounded-full ">
                <img src={advanced} />
              </span>

              {/* <h2 className="mt-4 text-lg font-medium text-gray-800 dark:text-white">Phone</h2> */}
              <p className="mt-2 ">
                THE KK INSTITUTE offers advanced media courses with A-grade
                experienced faculty to fulfill your dreams in the field of
                acting.
              </p>
              {/* <p className="mt-2 text-red-700 dark:text-blue-400">+1 (555) 000-0000</p> */}
            </div>
            <div className="flex flex-col items-center justify-center text-center">
              <span className="p-3 text-red-700 rounded-full ">
                <img src={challenge} />
              </span>

              {/* <h2 className="mt-4 text-lg font-medium text-gray-800 dark:text-white">Phone</h2> */}
              <p className="mt-2 ">
                THE KK INSTITUTE not only teaches acting but also grooms an
                individual to fight every challenges of day to day life.
              </p>
              {/* <p className="mt-2 text-red-700 dark:text-blue-400">+1 (555) 000-0000</p> */}
            </div>
          </div>
        </div>
      </section>

      <div className="">
        <img src={line} className="" />
      </div>

      <section className="bg-white ">
        <div className="container px-6 py-10 mx-auto">
          <div className="text-center">
            <h2 className="text-2xl font-semibold capitalize lg:text-3xl">
              Our Courses
            </h2>
            <div className="mt-2">
              <span className="inline-block w-40 h-1 bg-red-700 rounded-full"></span>
              <span className="inline-block w-3 h-1 ml-1 bg-red-700 rounded-full"></span>
              <span className="inline-block w-1 h-1 ml-1 bg-red-700 rounded-full"></span>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Card is full width. Use in 12 col grid for best view. -->
                    <!-- Card code block start --> */}
      <div className="w-full grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 px-10 pb-10">
        <a
          aria-label="card 1"
          href="/best-acting-classes"
          className=" rounded focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none focus:bg-red-100 hover:bg-red-100"
        >
          <div className="shadow px-8 py-6 text-center">
            <div className="w-20 h-20 mx-auto mb-4 ">
              {/* <img
                src="https://tuk-cdn.s3.amazonaws.com/can-uploader/medium_stat_cards_with_icon-svg1.svg"
                alt="icon"
                className="w-20 h-20 bg-black"
              /> */}
              <img alt="svgImg" src={acting} />
            </div>

            <h3 className="mb-1 leading-5 text-gray-800  font-bold text-2xl">
              Acting
            </h3>
          </div>
        </a>

        <a
          aria-label="card 1"
          href="/diploma-in-acting-complete-acting-course"
          className=" rounded focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none focus:bg-red-100 hover:bg-red-100"
        >
          <div className="shadow px-8 py-6 text-center">
            <div className="w-20 h-20 mx-auto mb-4 ">
              <img src={completeacting} className="w-20 h-20 " />
            </div>

            <h3 className="mb-1 leading-5 text-gray-800  font-bold text-2xl">
              Complete Acting
            </h3>
          </div>
        </a>
        <a
          aria-label="card 1"
          href="/best-dance-classes"
          className=" rounded focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none focus:bg-red-100 hover:bg-red-100"
        >
          <div className="shadow px-8 py-6 text-center">
            <div className="w-20 h-20 mx-auto mb-4 ">
              <img src={dance} alt="icon" className="w-20 h-20 " />
            </div>

            <h3 className="mb-1 leading-5 text-gray-800  font-bold text-2xl">
              Dance
            </h3>
          </div>
        </a>
        <a
          aria-label="card 1"
          href="/grooming-and-personal-dvelopment-classes"
          className=" rounded focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none focus:bg-red-100 hover:bg-red-100"
        >
          <div className="shadow px-8 py-6 text-center">
            <div className="w-20 h-20 mx-auto mb-4 ">
              <img src={grooming} alt="icon" className="w-20 h-20 " />
            </div>

            <h3 className="mb-1 leading-5 text-gray-800  font-bold text-2xl">
              Grooming
            </h3>
          </div>
        </a>
        <a
          aria-label="card 1"
          href="/best-modelling-agency"
          className=" rounded focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none focus:bg-red-100 hover:bg-red-100"
        >
          <div className="shadow px-8 py-6 text-center">
            <div className="w-20 h-20 mx-auto mb-4 ">
              <img src={modelling} alt="icon" className="w-20 h-20 " />
            </div>

            <h3 className="mb-1 leading-5 text-gray-800  font-bold text-2xl">
              Modelling
            </h3>
          </div>
        </a>
        <a
          aria-label="card 1"
          href="/professional-photoshoot"
          className=" rounded focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none focus:bg-red-100 hover:bg-red-100"
        >
          <div className="shadow px-8 py-6 text-center">
            <div className="w-20 h-20 mx-auto mb-4 ">
              <img src={photoshoot} alt="icon" className="w-20 h-20 " />
            </div>

            <h3 className="mb-1 leading-5 text-gray-800  font-bold text-2xl">
              Portfolio
            </h3>
          </div>
        </a>
        <a
          aria-label="card 1"
          href="/singing-classes-music-classes"
          className=" rounded focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none focus:bg-red-100 hover:bg-red-100"
        >
          <div className="shadow px-8 py-6 text-center">
            <div className="w-20 h-20 mx-auto mb-4 ">
              <img src={singing} alt="icon" className="w-20 h-20" />
            </div>

            <h3 className="mb-1 leading-5 text-gray-800  font-bold text-2xl">
              Singing
            </h3>
          </div>
        </a>

        <a
          aria-label="card 1"
          href="/yoga-classes"
          className=" rounded focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none focus:bg-red-100 hover:bg-red-100"
        >
          <div className="shadow px-8 py-6 text-center">
            <div className="w-20 h-20 mx-auto mb-4 ">
              <img src={yoga} alt="icon" className="w-20 h-20" />
            </div>

            <h3 className="mb-1 leading-5 text-gray-800  font-bold text-2xl">
              Yoga
            </h3>
          </div>
        </a>
        <a
          aria-label="card 1"
          href="/zumba-classes-zumba-dance-classes"
          className=" rounded focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none focus:bg-red-100 hover:bg-red-100"
        >
          <div className="shadow px-8 py-6 text-center">
            <div className="w-20 h-20 mx-auto mb-4 ">
              <img src={zumba} alt="icon" className="w-20 h-20 " />
            </div>

            <h3 className="mb-1 leading-5 text-gray-800  font-bold text-2xl">
              Zumba
            </h3>
          </div>
        </a>
        <a
          aria-label="card 1"
          href="/best-martial-arts-classes"
          className=" rounded focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none focus:bg-red-100 hover:bg-red-100"
        >
          <div className="shadow px-8 py-6 text-center">
            <div className="w-20 h-20 mx-auto mb-4 ">
              <img src={mma} alt="icon" className="w-20 h-20 " />
            </div>

            <h3 className="mb-1 leading-5 text-gray-800  font-bold text-2xl">
              MMA
            </h3>
          </div>
        </a>
        <a
          aria-label="card 1"
          href="/best-acting-school-for-kids-and-children"
          className=" rounded focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none focus:bg-red-100 hover:bg-red-100"
        >
          <div className="shadow px-8 py-6 text-center">
            <div className="w-20 h-20 mx-auto mb-4 ">
              <img src={childacting} alt="icon" className="w-20 h-20 " />
            </div>

            <h3 className="mb-1 leading-5 text-gray-800  font-bold text-2xl">
              Child Acting
            </h3>
          </div>
        </a>
        <a
          aria-label="card 1"
          href="/film-acting-school"
          className=" rounded focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none focus:bg-red-100 hover:bg-red-100"
        >
          <div className="shadow px-8 py-6 text-center">
            <div className="w-20 h-20 mx-auto mb-4 ">
              <img src={filmacting} alt="icon" className="w-20 h-20 " />
            </div>

            <h3 className="mb-1 leading-5 text-gray-800  font-bold text-2xl">
              Film Acting
            </h3>
          </div>
        </a>
      </div>
      {/* <!-- Card code block end --> */}

      <div>
        <img src={ticket} />
      </div>
      <div className="mt-5">
        <img src={line} className="" />
      </div>
      <section className="text-gray-600 body-font">
        <div className="container px-5  mx-auto flex flex-wrap">
          <div className="lg:w-1/2 w-full mt-10 mb-10 lg:mb-0 rounded-lg overflow-hidden">
            <img
              alt="feature"
              className="object-cover object-center h-full w-full"
              src={no}
            />
          </div>
          <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center items-center justify-center">
            <section className=" dark:text-black">
              <div className="container max-w-5xl px-4 py-12 mx-auto">
                <div className="grid gap-4 mx-4 sm:grid-cols-12">
                  <div className="relative col-span-12 px-4 space-y-6 sm:col-span-9">
                    <div className="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:dark:bg-gray-700">
                      <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-red-700">
                        <h3 className="text-xl font-semibold tracking-wide">
                          Have own production house{" "}
                        </h3>

                        <p className="mt-3">
                          We have our own production house for performing arts
                          and films.
                        </p>
                      </div>
                      <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-red-700">
                        <h3 className="text-xl font-semibold tracking-wide">
                          Have produced hindi movies
                        </h3>

                        <p className="mt-3">
                          Hindi movies often revolve around themes such as love,
                          family, and friendship, and usually, the storyline.
                        </p>
                      </div>
                      <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-red-700">
                        <h3 className="text-xl font-semibold tracking-wide">
                          Have own music channels
                        </h3>

                        <p className="mt-3">
                          Having own music channel can be an exciting
                          opportunity to showcase your love for music.
                        </p>
                      </div>
                      <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-red-700">
                        <h3 className="text-xl font-semibold tracking-wide">
                          Have own Entertainment channels
                        </h3>

                        <p className="mt-3">
                          Having our own entertainment channel can be a great
                          way to showcase your creativity and share your passion
                          for entertainment with others.{" "}
                        </p>
                      </div>
                      <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-red-700">
                        <h3 className="text-xl font-semibold tracking-wide">
                          Producing Gujarati movies in 2023
                        </h3>

                        <p className="mt-3">
                          Producing Gujarati movies in 2023 presents a promising
                          opportunity for filmmakers to explore the unique
                          cultural heritage of Gujarat and showcase it to a
                          wider audience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <Footer />
    </motion.div>
  );
}

export default Home;
