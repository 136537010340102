import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "../Firebase";
import Footer from "../Footer";
import Nav from "../Nav";
import { motion } from "framer-motion";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { Helmet } from "react-helmet";
function Grooming() {
  const [actingPackages, setActingPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    course: "select course",
  });
  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Form Data"), formData);
      // Success! Data is saved to Firestore.
      toast("Form data saved");
      // Reset the form
      setFormData({
        name: "",
        email: "",
        number: "",
        course: "select course",
      });
    } catch (error) {
      // Handle error
      toast("Error saving form data:", error);
    }
  };
  useEffect(() => {
    const fetchActingPackages = async () => {
      try {
        const packagesRef = collection(db, "packages");
        const q = query(packagesRef, where("course", "==", "Grooming"));
        const querySnapshot = await getDocs(q);

        const actingPackageData = querySnapshot.docs.map((doc) => doc.data());
        const sortedActingPackages = actingPackageData.sort(
          (a, b) => a.amount - b.amount
        );
        setActingPackages(sortedActingPackages);
        setLoading(false);
      } catch (error) {
        toast("Error fetching packages:", error);
        setError("Error fetching  packages");
        setLoading(false);
      }
    };

    fetchActingPackages();

    const fetchImages = async () => {
      const storage = getStorage();
      const coursesRef = ref(storage, "courses");
      const actingRef = ref(coursesRef, "Grooming");

      try {
        const imageList = await listAll(actingRef);
        const imageURLs = await Promise.all(
          imageList.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            // console.log(downloadURL);
            return downloadURL;
          })
        );
        setImages(imageURLs);
        // console.log(imageURLs);
      } catch (error) {
        toast("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div>
      <Nav />
      <Helmet>
        <title>Grooming and Personal Development Classes</title>
        <meta
          name="description"
          content="At The KK Institute we provide grooming and personal development classes for a brighter, confident future."
        />
      </Helmet>
      <section className="relative h-screen overflow-hidden">
        {images.map((imageUrl, index) => (
          <motion.img
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={imageUrl}
            alt={`Image ${index}`}
          />
        ))}
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div className="container mx-auto h-full flex items-center justify-center">
          <motion.div
            initial={{ opacity: 1, y: -20 }}
            animate={{ opacity: 1, y: 20 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="text-center text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              Give yourself a brand new look
            </h1>
            <p className="text-lg md:text-2xl mb-6">
              Release the attractiveness in you
            </p>
          </motion.div>
        </div>
      </section>
      <h2 className="my-5 text-center text-5xl font-bold">
        <span className="text-red-500">Grooming and Personal Development </span>
        Classes in Ahmedabad
      </h2>
      <section className="bg-white">
        <div className="container mx-auto flex flex-wrap items-center">
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-2/3 mb-8 md:mb-0"
          >
            <div className="px-8">
              <h2 className="text-4xl font-bold mb-4">
                Grooming – An essence of elegance.
              </h2>
              <p className="text-gray-600 mb-8">
                Grooming and personal development classes are beneficial for
                individuals looking to enhance their appearance, refine their
                social skills and improve their overall self-confidence. THE KK
                INSTITUTE is one of the leading grooming and personal
                development classes in ahmedabad covering a wide range of topics
                and skills, helping participants become more well-rounded and
                presentable individuals.
              </p>
              <p className="text-gray-600 mb-8">
                In the contemporary world, people across various sectors
                universally appreciate the presence of individuals who project a
                pleasant and well-maintained image. While grooming is a
                lifestyle norm in the corporate world, it transforms into an
                absolute necessity within the realms of the performing arts,
                whether it's modelling or acting. If you possess a well-groomed
                appearance coupled with a determined mindset, the path to
                achieving superstardom can transition from a dream to a
                potential reality. For those harbouring aspirations of becoming
                a model or actor, as the top grooming and personal development
                classes in ahmedabad we provide some valuable tips to help you
                embark on your journey.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3 px-8"
          >
            <form onSubmit={handleSubmit}>
              {/* <!-- component --> */}
              <div className="min-h-screen  py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                  <div className="absolute inset-0 bg-gradient-to-r from-red-300 to-red-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                  <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                      <div>
                        <h2 className="text-2xl font-semibold">Enroll Now</h2>
                      </div>
                      <div className="divide-y divide-gray-200">
                        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="name"
                            />
                            <label
                              htmlFor="name"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Name
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Email address"
                            />
                            <label
                              htmlFor="email"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Email Address
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="number"
                              name="number"
                              type="number"
                              value={formData.number}
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Password"
                            />
                            <label
                              htmlFor="number"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Phone
                            </label>
                          </div>
                          <div className="relative">
                            <select
                              value={formData.course}
                              name="course"
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-600 focus:outline-none focus:borer-rose-600"
                            >
                              <option>Select Course</option>
                              <option>Complete Acting Course</option>
                              <option>Acting</option>
                              <option>Film Acting Course</option>
                              <option>Child Acting</option>
                              <option>Mix Martial Arts</option>
                              <option>Photoshoot</option>
                              <option>Dancing</option>
                              <option>Modelling</option>
                              <option>Grooming</option>
                              <option>Singing</option>
                              <option>Yoga</option>
                              <option>Zumba</option>
                            </select>
                            {/* <label htmlFor="number" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">phone</label> */}
                          </div>

                          <div className="relative">
                            <button className="bg-red-500 text-white rounded-md px-2 py-1">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </section>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white p-8 rounded-lg  text-gray-800"
      >
        <div className="flex flex-col gap-8">
          <h2 className="text-2xl font-bold text-red-700">A - Appearance</h2>
          <p className="text-lg">
            Maintaining an impeccable appearance is paramount. Regular exercise,
            a balanced diet, and proper grooming are essential for a healthy and
            attractive physique. Invest in a diverse wardrobe that suits various
            roles and situations, and ensure your clothing is clean and
            well-fitted. Professional headshots are your calling card; hire a
            skilled photographer to capture your best angles and features.
          </p>
          <h2 className="text-2xl font-bold text-red-700">B - Behaviour</h2>
          <p className="text-lg">
            Professionalism is key. Always be punctual, dependable and
            respectful to industry professionals, from casting directors to
            fellow performers. Embrace adaptability; be open to feedback and
            willing to take on a variety of roles. Confidence is crucial, but it
            should be balanced with humility and a positive attitude. Commit to
            a strong work ethic, as success often demands persistence and
            dedication.
          </p>
          <h2 className="text-2xl font-bold text-red-700">C - Communication</h2>
          <p className="text-lg">
            Develop effective communication skills to excel in auditions and
            on-set work. Cultivate clear and articulate speech with good diction
            and pronunciation. Master non-verbal communication by honing your
            body language and facial expressions to convey emotions
            convincingly. Actively listen to instructions, feedback, and the
            needs of the production team. Finally, build industry relationships
            by networking at events, workshops and by connecting with
            professionals who can help further your career.
          </p>
        </div>
      </motion.div>

      <div className="mt-5 h-full mb-20 flex flex-col justify-center items-center px-4 lg:px-0">
        <div className="w-full max-w-3xl mx-auto">
          <div className="text-center font-semibold">
            <h2 className="text-3xl md:text-5xl">
              <span className="text-red-700 tracking-wide">Grooming </span>
              <span>packages</span>
            </h2>
            <p className="pt-4 md:pt-6 text-base md:text-xl text-gray-400 font-normal">
              Choose a plan that works best for you
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between pt-8 md:pt-24">
            {actingPackages.map((p) => (
              <div
                key={p.packageType}
                className={`w-full md:w-1/3 p-4 md:p-8 even:bg-black even:text-white text-center rounded-3xl shadow-xl`}
              >
                <h2 className="text-lg md:text-2xl font-semibold">
                  {p.packageType}
                </h2>
                <p className="pt-2 tracking-wide">
                  <span className="text-gray-400 align-top">₹ </span>
                  <span className="text-xl md:text-3xl font-semibold">
                    {p.amount}
                  </span>
                </p>
                <hr className="mt-4 border-1" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default Grooming;
