import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "../Firebase";
import Footer from "../Footer";
import Nav from "../Nav";
import { motion } from "framer-motion";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { Helmet } from "react-helmet";
function Zumba() {
  const [actingPackages, setActingPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    course: "select course",
  });
  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Form Data"), formData);
      // Success! Data is saved to Firestore.
      toast("Form data saved");
      // Reset the form
      setFormData({
        name: "",
        email: "",
        number: "",
        course: "select course",
      });
    } catch (error) {
      // Handle error
      toast("Error saving form data: ", error);
    }
  };
  useEffect(() => {
    const fetchActingPackages = async () => {
      try {
        const packagesRef = collection(db, "packages");
        const q = query(packagesRef, where("course", "==", "Zumba"));
        const querySnapshot = await getDocs(q);

        const actingPackageData = querySnapshot.docs.map((doc) => doc.data());
        const sortedActingPackages = actingPackageData.sort(
          (a, b) => a.amount - b.amount
        );
        setActingPackages(sortedActingPackages);
        setLoading(false);
      } catch (error) {
        toast("Error fetching  packages:", error);
        setError("Error fetching  packages");
        setLoading(false);
      }
    };

    fetchActingPackages();
    const fetchImages = async () => {
      const storage = getStorage();
      const coursesRef = ref(storage, "courses");
      const actingRef = ref(coursesRef, "Zumba");

      try {
        const imageList = await listAll(actingRef);
        const imageURLs = await Promise.all(
          imageList.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            // console.log(downloadURL);
            return downloadURL;
          })
        );
        setImages(imageURLs);
        // console.log(imageURLs);
      } catch (error) {
        toast("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const benefits = [
    {
      title: "It's fun",
      description:
        "The more you enjoy your exercise routine, the more likely you are to stick with it.",
    },
    {
      title: "Great for weight loss",
      description: "It will burn calories and fat.",
    },
    {
      title: "Tones your entire body",
      description: "It is a full-body workout.",
    },
    {
      title: "Boosts your heart health",
      description: "Improving cardiovascular fitness.",
    },
    {
      title: "Helps you de-stress",
      description: "Reduces stress and anxiety.",
    },
    {
      title: "Improves coordination",
      description: "Enhances motor skills and balance.",
    },
    {
      title: "Makes you happy",
      description: "Releases endorphins, the feel-good hormones.",
    },
    {
      title: "Building endurance",
      description: "Increases stamina and endurance.",
    },
    {
      title: "Improved blood pressure",
      description: "Helps maintain healthy blood pressure levels.",
    },
    {
      title: "It is adaptable for any fitness level",
      description: "Suitable for beginners to advanced fitness levels.",
    },
    {
      title: "It is social",
      description: "Allows for social interaction and community engagement.",
    },
    {
      title: "It can increase your pain threshold",
      description: "Enhances pain tolerance and resilience.",
    },
    {
      title: "You can improve your quality of life",
      description: "Enhances overall physical and mental well-being.",
    },
    {
      title: "Assist Metabolism",
      description: "Boosts metabolism and aids in weight management.",
    },
    {
      title: "Boost Your Mood",
      description: "Improves mood and reduces symptoms of depression.",
    },
    {
      title: "Learn how to dance",
      description: "Provides an opportunity to learn various dance styles.",
    },
    {
      title: "Feel Like You're Part of a Community",
      description:
        "Fosters a sense of belonging and community among participants.",
    },
  ];

  return (
    <div>
      <Nav />
      <Helmet>
        <title>Zumba Classes in Ahmedabad | Zumba Dance Classes</title>
        <meta
          name="description"
          content="Amazing zumba classes in ahmedabad  providing a total body workout combining cardio, muscle conditioning, balance and flexibility"
        />
      </Helmet>
      <section className="relative h-screen overflow-hidden">
        {images.map((imageUrl, index) => (
          <motion.img
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={imageUrl}
            alt={`Image ${index}`}
          />
        ))}
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div className="container mx-auto h-full flex items-center justify-center">
          <motion.div
            initial={{ opacity: 1, y: -20 }}
            animate={{ opacity: 1, y: 20 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="text-center text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              Welcome to Zumba!
            </h1>
            <p className="text-lg md:text-2xl mb-6">
              Join us for a fun and energetic dance workout experience.
            </p>
          </motion.div>
        </div>
      </section>
      <h2 className="my-5 text-center text-5xl font-bold">
        <span className="text-red-500">Best </span>
        Zumba Classes in Ahmedabad
      </h2>
      <section className="">
        <div className="container mx-auto flex flex-wrap items-center">
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-2/3 mb-8 md:mb-0"
          >
            <div className="px-8">
              <h2 className="text-4xl font-bold mb-4">Introduction to Zumba</h2>
              <p className="text-gray-600 mb-8">
                A best zumba class is a full hour of continuous dancing to
                social-dance music. It provides a high level of aerobic exercise
                and is structured according to the same principles as interval
                training, alternating between fast and slow musical selections
                that are designed to tone your body while burning fat. A total
                body workout combining cardio, muscle conditioning, balance and
                flexibility.
              </p>
              <p className="text-gray-600 mb-8">
                THE KK INSTITUTE is the best zumba class in ahmedabad. Zumba has
                surged in popularity due to its unique blend of fitness and fun,
                making it a standout in the world of exercise. This
                Latin-inspired dance fitness program offers a dynamic and
                engaging way to stay active, appealing to people of all ages and
                fitness levels. One of its key attractions is the infectious
                energy it exudes; participants groove to catchy rhythms,
                allowing them to forget they're even working out. Zumba's
                inclusivity is another major draw, as it welcomes everyone,
                regardless of their dance background or fitness expertise.
                Moreover, it provides a full-body workout, incorporating cardio,
                strength training and flexibility exercises, ensuring that
                participants see tangible fitness results. The sense of
                community fostered in Zumba classes further contributes to its
                popularity, as people come together to dance, socialise and
                support one another on their fitness journeys. Overall, zumba's
                ability to make exercise feel like a celebration has undoubtedly
                played a significant role in its widespread appeal, making it a
                favourite fitness choice for many.
              </p>
              <p className="text-gray-600 mb-8">
                THE KK INSTITUTE is widely recognized as the best zumba dance
                classes in ahmedabad. With a stellar reputation and a dedicated
                team of experienced instructors, it has earned its status as the
                best in the city.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3 px-8"
          >
            <form onSubmit={handleSubmit}>
              {/* <!-- component --> */}
              <div className="min-h-screen  py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                  <div className="absolute inset-0 bg-gradient-to-r from-red-300 to-red-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                  <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                      <div>
                        <h2 className="text-2xl font-semibold">Enroll Now</h2>
                      </div>
                      <div className="divide-y divide-gray-200">
                        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="name"
                            />
                            <label
                              htmlFor="name"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Name
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Email address"
                            />
                            <label
                              htmlFor="email"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Email Address
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="number"
                              name="number"
                              type="number"
                              value={formData.number}
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Password"
                            />
                            <label
                              htmlFor="number"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Phone
                            </label>
                          </div>
                          <div className="relative">
                            <select
                              value={formData.course}
                              name="course"
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-600 focus:outline-none focus:borer-rose-600"
                            >
                              <option>Select Course</option>
                              <option>Complete Acting Course</option>
                              <option>Acting</option>
                              <option>Film Acting Course</option>
                              <option>Child Acting</option>
                              <option>Mix Martial Arts</option>
                              <option>Photoshoot</option>
                              <option>Dancing</option>
                              <option>Modelling</option>
                              <option>Grooming</option>
                              <option>Singing</option>
                              <option>Yoga</option>
                              <option>Zumba</option>
                            </select>
                            {/* <label htmlFor="number" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">phone</label> */}
                          </div>

                          <div className="relative">
                            <button className="bg-red-500 text-white rounded-md px-2 py-1">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </section>
      <div className="min-h-screen py-12 ">
        <section>
          <section className="bg-white ">
            <div className="container px-6  mx-auto">
              <div className="text-center">
                <h2 className="text-2xl font-semibold capitalize lg:text-3xl">
                  Benefits of Zumba
                </h2>
                <div className="">
                  <span className="inline-block w-40 h-1 bg-red-700 rounded-full"></span>
                  <span className="inline-block w-3 h-1 ml-1 bg-red-700 rounded-full"></span>
                  <span className="inline-block w-1 h-1 ml-1 bg-red-700 rounded-full"></span>
                </div>
              </div>
            </div>
          </section>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 ml-10">
            {benefits.map((benefit, index) => (
              <div className="flex lg:w-1/4 lg:my-8">
                <div className="relative w-64 h-48">
                  <div className="absolute top-0 left-0 flex items-center w-96 h-40 mt-6 ml-6 bg-white border-8 border-gray-700 border-solid rounded-lg">
                    <div className="w-1/3 h-40"></div>
                    <div className="w-2/3 h-32 pr-4">
                      <h3 className="pt-1 text-xl font-semibold text-gray-700">
                        {benefit.title}
                      </h3>
                      <p className="pt-1 text-base text-gray-600">
                        {benefit.description}
                      </p>
                    </div>
                  </div>
                  <div className="absolute top-0 left-0 z-20 w-12 h-12 mt-6 ml-6 bg-white rounded-full">
                    <svg
                      className="mt-2 ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="#e53e3e"
                      width="32px"
                      height="32px"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" />
                    </svg>
                  </div>
                  <div className="absolute top-0 left-0 z-10 w-24 h-40 py-20 text-5xl font-bold text-center text-white bg-red-600 rounded-lg">
                    {index + 1}
                  </div>
                  <div className="absolute top-0 left-0 z-30 w-24 h-2 mt-40 ml-48 bg-red-600"></div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>

      <div className="mt-5 h-full mb-20 flex flex-col justify-center items-center px-4 lg:px-0">
        <div className="w-full max-w-3xl mx-auto">
          <div className="text-center font-semibold">
            <h2 className="text-3xl md:text-5xl">
              <span className="text-red-700 tracking-wide">Zumba </span>
              <span>packages</span>
            </h2>
            <p className="pt-4 md:pt-6 text-base md:text-xl text-gray-400 font-normal">
              Choose a plan that works best for you
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between pt-8 md:pt-24">
            {actingPackages.map((p) => (
              <div
                key={p.packageType}
                className={`w-full md:w-1/3 p-4 md:p-8 even:bg-black even:text-white text-center rounded-3xl shadow-xl`}
              >
                <h2 className="text-lg md:text-2xl font-semibold">
                  {p.packageType}
                </h2>
                <p className="pt-2 tracking-wide">
                  <span className="text-gray-400 align-top">₹ </span>
                  <span className="text-xl md:text-3xl font-semibold">
                    {p.amount}
                  </span>
                </p>
                <hr className="mt-4 border-1" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default Zumba;
