import React, { useState, useEffect } from "react";
import { getStorage, ref, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import Signin from "./Signin";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function Images2() {
  const [user, setUser] = useState(null);
  const [courseImages, setCourseImages] = useState([]);

  useEffect(() => {
    const fetchCourseImages = async () => {
      const storage = getStorage();
      const coursesRef = ref(storage, "courses");
  
      try {
        const courseList = await listAll(coursesRef);
        const imagePromises = [];

        for (const course of courseList.prefixes) {
          const courseImages = await listAll(course);
          for (const imageRef of courseImages.items) {
            const downloadURL = await getDownloadURL(imageRef);
            imagePromises.push({ downloadURL, ref: imageRef });
          }
        }

        const courseImageURLs = await Promise.all(imagePromises);
        setCourseImages(courseImageURLs);
      } catch (error) {
        toast("Error fetching course images:", error);
      }
    };

    fetchCourseImages();
  }, []);

  const handleDelete = async (imageRef) => {
    try {
      const storage = getStorage();
      await deleteObject(imageRef);
    
      setCourseImages((prevImages) =>
        prevImages.filter((image) => image.ref.fullPath !== imageRef.fullPath)
      );
      toast(imageRef.name)
      toast(`${imageRef.name} deleted successfully.`);
    } catch (error) {
      toast(`Error deleting image:`, error);
    }
  };

  // Rest of your code...

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {/* Rest of your JSX */}
      {courseImages.map((image, index) => (
       
        <div key={index} className="bg-gray-200 rounded-lg p-4 transition duration-500 ease-in-out">
          <img src={image.downloadURL} alt={`Image ${index}`} className="w-full h-40 object-cover rounded" />
        <div className="flex justify-center mt-2">  <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded" onClick={() => handleDelete(image.ref)}>Delete</button>
        </div>
        </div>
      ))}
            <ToastContainer />
    </div>
  );
}

export default Images2;
