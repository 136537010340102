import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer";
import Nav from "../Nav";
import { motion } from "framer-motion";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { Helmet } from "react-helmet";
function Photoshoot() {
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    course: "select course",
  });
  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Form Data"), formData);
      // Success! Data is saved to Firestore.
      toast("Form data saved ");
      // Reset the form
      setFormData({
        name: "",
        email: "",
        number: "",
        course: "select course",
      });
    } catch (error) {
      // Handle error
      toast("Error saving form data: ", error);
    }
  };
  useEffect(() => {
    const fetchImages = async () => {
      const storage = getStorage();
      const coursesRef = ref(storage, "courses");
      const actingRef = ref(coursesRef, "Protfolio");

      try {
        const imageList = await listAll(actingRef);
        const imageURLs = await Promise.all(
          imageList.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            // console.log(downloadURL);
            return downloadURL;
          })
        );
        setImages(imageURLs);
        // console.log(imageURLs);
      } catch (error) {
        console.log("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);
  const infoPoints = [
    {
      title: "Engage with Casting Agencies",
      description:
        "Move forward proactively by sharing your portfolio with reputable casting directors. Approach auditions with thorough preparation. Conduct extensive market research to identify respected agencies. Feel confident in reaching out to the right individuals at the opportune moments.",
    },
    {
      title: "Be well-groomed",
      description:
        "Maintaining a polished appearance is crucial, as the saying goes, Grooming is the epitome of elegance. Grooming and self-care should be ingrained in your daily routine. Whether you're stepping into an audition room or living your everyday life, consistently keeping up with your appearance is essential. Grooming is a way of showing self-respect; therefore, prioritise good nutrition, dress impeccably, and be mindful of your personal needs and image. It's when you do these things that the world will truly take notice of you!",
    },
    {
      title: "Embrace your authenticity; Be true to yourself",
      description:
        "Your true beauty shines when you embrace your natural self. Your portfolio should authentically reflect your raw, unfiltered beauty. There's no need to conceal your natural complexion with heavy makeup or alter your body size with professional photoshoot tricks. The industry values individuals who are genuine and self-assured.",
    },
    {
      title: "Persevere",
      description:
        "In the journey to success, patience and unwavering determination are the keys. Rejections may come your way numerous times, but it's essential not to let your spirit waver. Instead, lift yourself up, dedicate yourself to continuous improvement and persistently pursue auditions. ",
    },
    {
      title: "Stay Alert to Deceptive Promises",
      description:
        "In major cities such as Delhi and Mumbai, a proliferation of counterfeit agencies has occurred. Be cautious of these fraudulent entities that may dangle enticing offers of higher pay or opportunities beyond your fresher status. Refrain from getting ensnared in their deceitful schemes. Instead, focus on building connections with reputable individuals in the industry",
    },
    {
      title:
        "Embrace Positivity, Exude Professionalism and Nurture Your Passion!",
      description:
        "Our portfolio photoshoot is a meticulously crafted collection, comprising a diverse array of images captured in both fully equipped studios and the great outdoors. Renowned makeup artists and hair stylists work their magic, enhancing your unique beauty, complemented by the perfect costumes that accentuate your frame and bone structure. We offer comprehensive guidance to pave the way for a thriving modelling career, assisting with strategic promotion and planning for your success.",
    },
  ];
  const testimonialData = [
    {
      quote: "Part of an actor’s resume",
      //   author: "Jane Doe, Actress"
    },
    {
      quote: "Online casting profiles",
      author: "John Smith, Actor",
    },
    {
      quote: "Personal actor websites",
      author: "Sarah Johnson, Actress",
    },
    {
      quote: "Professional social media profiles",
      author: "Michael Brown, Actor",
    },
    {
      quote: "Marketing materials",
      author: "Alex Thompson, Actress",
    },
    {
      quote: "Leaves a lasting impression",
      author: "David Lee, Actor",
    },
    {
      quote: "Helps actors become more camera-friendly",
      author: "Olivia Chen, Actress",
    },
    {
      quote: "Serves as a canvas",
      author: "Daniel Kim, Actor",
    },
  ];

  return (
    <div>
      <Nav />
      <Helmet>
        <title>
          Professional Photoshoot Company | Modelling Photography Agency
        </title>
        <meta
          name="description"
          content="Your modelling and acting career starts here. The best professional photoshoot for actors and models at The KK Institute. We make a professional portfolio."
        />
      </Helmet>
      <section className="relative h-screen overflow-hidden">
        {images.map((imageUrl, index) => (
          <motion.img
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={imageUrl}
            alt={`Image ${index}`}
          />
        ))}
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div className="container mx-auto h-full flex items-center justify-center">
          <motion.div
            initial={{ opacity: 1, y: -20 }}
            animate={{ opacity: 1, y: 20 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="text-center text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              Capturing Moments
            </h1>
            <p className="text-lg md:text-2xl mb-6">Creating Memories</p>
          </motion.div>
        </div>
      </section>
      <h2 className="my-5 text-center text-5xl font-bold">
        <span className="text-red-500">
          Professional Modelling Photography{" "}
        </span>
        Agency in ahmedabad
      </h2>
      <section className="bg-white">
        <div className="container mx-auto flex flex-wrap items-center">
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-2/3 mb-8 md:mb-0"
          >
            <div className="px-8">
              <h2 className="text-4xl font-bold mb-4">
                Why do you need a professional PHOTOSHOOT?
              </h2>
              <p className="text-gray-600 mb-8">
                One of the first and the most important things asked by casting
                directors is a professional photoshoot/portfolio.
              </p>
              <p className="text-gray-600 mb-8">
                Great quality photos that show what you look like, have a great
                expression and are in colour. Look up professional actor
                photoshoots on google to get an idea of how they should look.
                It's important to bear in mind that casting directors often use
                your photoshoot as an indicator of your commitment to your
                career. Having a professional photoshoot demonstrates your
                seriousness, while not having one may convey a lack of
                commitment, potentially reducing your chances of being hired by
                them.
              </p>
              <p className="text-gray-600 mb-8">
                If you're looking for a professional modelling photo shoot
                agency to help you pursue a modelling career then THE KK
                INSTITUTE is the best modelling photography agency in ahmedabad
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3 px-8"
          >
            <form onSubmit={handleSubmit}>
              {/* <!-- component --> */}
              <div className="min-h-screen  py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                  <div className="absolute inset-0 bg-gradient-to-r from-red-300 to-red-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                  <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                      <div>
                        <h2 className="text-2xl font-semibold">Enroll Now</h2>
                      </div>
                      <div className="divide-y divide-gray-200">
                        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="name"
                            />
                            <label
                              htmlFor="name"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Name
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Email address"
                            />
                            <label
                              htmlFor="email"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Email Address
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="number"
                              name="number"
                              type="number"
                              value={formData.number}
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Password"
                            />
                            <label
                              htmlFor="number"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Phone
                            </label>
                          </div>
                          <div className="relative">
                            <select
                              value={formData.course}
                              name="course"
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-600 focus:outline-none focus:borer-rose-600"
                            >
                              <option>Select Course</option>
                              <option>Complete Acting Course</option>
                              <option>Acting</option>
                              <option>Film Acting Course</option>
                              <option>Child Acting</option>
                              <option>Mix Martial Arts</option>
                              <option>Photoshoot</option>
                              <option>Dancing</option>
                              <option>Modelling</option>
                              <option>Grooming</option>
                              <option>Singing</option>
                              <option>Yoga</option>
                              <option>Zumba</option>
                            </select>
                            {/* <label htmlFor="number" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">phone</label> */}
                          </div>

                          <div className="relative">
                            <button className="bg-red-500 text-white rounded-md px-2 py-1">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </section>
      <center>
        <h2 className="text-3xl font-bold mb-4">Actors need photoshoot for</h2>
      </center>
      <div className="container mx-auto px-4 flex flex-wrap justify-center">
        {/* Other content in your app */}

        {testimonialData.map((testimonial, index) => (
          <motion.div
            key={index}
            className="bg-white shadow-lg rounded-md p-6 m-4 w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <p className="text-gray-600 text-center text-lg mb-4">
              {testimonial.quote}
            </p>
            {/* <p className="text-gray-800 font-semibold">{testimonial.author}</p> */}
          </motion.div>
        ))}
      </div>

      <div className="bg-white py-12">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">
            WHAT SHOULD ASPIRING ACTORS/MODELS DO AFTER THEIR PORTFOLIO SHOOT?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
            {infoPoints.map((point, index) => (
              <motion.div
                key={index}
                className="bg-white p-6 rounded-lg shadow-md"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <h3 className="text-xl font-semibold mb-2">{point.title}</h3>
                <p className="text-gray-600 mb-2">{point.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      <motion.div
        className="bg-white py-12"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div
            className=" items-center justify-center mb-5"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            {/* <img src="https://media.istockphoto.com/id/621263882/photo/little-7-years-old-boy-browsing-old-photo-album.jpg?b=1&s=170667a&w=0&k=20&c=kGA-qFco4pKrJyYUBKKBwi9dLgdrDRUXWP6Sr6OSn-8=" alt="Testimonial Avatar" className="w-16 h-16 rounded-full mr-4" /> */}
            <div>
              <motion.h2
                className="text-3xl text-center font-semibold"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                Kids Portfolio
              </motion.h2>
              <motion.p
                className="text-gray-600 text-center text-sm italic mt-1"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                The "Kids Portfolio" package can be used for Modeling, Acting
                and Social Media. Age group - 4 years to 12 years
              </motion.p>
            </div>
          </div>
          <motion.p
            className="text-gray-600 text-lg italic text-center mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            A kid’s portfolio shoot at THE KK INSTITUTE is an excellent way to
            capture your child's unique personality and create lasting memories.
            The studio offers a team of experienced photographers who specialize
            in children's photography and have a playful and friendly demeanor
            that helps your child feel comfortable and at ease during the shoot.
          </motion.p>
          <motion.p
            className="text-gray-600 text-lg italic text-center mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            During the shoot, the team remains patient and flexible, allowing
            your child to take breaks and have fun when needed. They know how to
            work with kids and capture their natural expressions and movements,
            resulting in beautiful and authentic photographs.
          </motion.p>
        </div>
      </motion.div>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-8 sm:p-10 text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 mb-4">
              PORTFOLIO PACKAGE FOR ADULTS & KIDS
            </h2>
            <p className="text-gray-600 mb-4">PACKAGE COST: 20000</p>
            <ul className="list-disc  list-inside mb-8">
              <li className="flex items-center mb-2">
                <span className="text-blue-600 mr-2">&#x2713;</span>
                PHOTOGRAPHY
              </li>
              <li className="flex items-center mb-2">
                <span className="text-blue-600 mr-2">&#x2713;</span>
                MAKE UP
              </li>
              <li className="flex items-center mb-2">
                <span className="text-blue-600 mr-2">&#x2713;</span>
                HAIR STYLIST
              </li>
              <li className="flex items-center mb-2">
                <span className="text-blue-600 mr-2">&#x2713;</span>5 LOOKS
              </li>
              <li className="flex items-center mb-2">
                <span className="text-blue-600 mr-2">&#x2713;</span>
                15 BEST IMAGES
              </li>
              <li className="flex items-center mb-2">
                <span className="text-blue-600 mr-2">&#x2713;</span>
                Indoor studio for the shoot
              </li>
              <li className="flex items-center mb-2">
                <span className="text-blue-600 mr-2">&#x2713;</span>5 hours of
                shoot time
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
      <ToastContainer />
    </div>
  );
}

export default Photoshoot;
