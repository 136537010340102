import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../Firebase";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const auth = getAuth(app);
function Signin() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const signinUser = () => {
    if (email.trim() !== "" && password.trim() !== "") {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          // toast("Login Successfully");
          navigate("/Banner");
        })
        .catch((err) => toast(" Incorrect email or password"));
    }
  };
  return (
    <div>
      {/* <!-- component --> */}
      <section class="flex flex-col md:flex-row h-screen items-center">
        <div class="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
          <img
            src="https://media.istockphoto.com/id/1367444401/photo/woman-using-access-window-to-log-in-entering-password-on-laptop-sign-up-username-password.jpg?b=1&s=170667a&w=0&k=20&c=objZZbDXcj13-8STNhcc4gTB-EDCudwgL26j-0gAynA="
            alt=""
            class="w-full h-full object-cover"
          />
        </div>

        <div
          class="bg-white w-full md:max-w-md lg:max-w-full  md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12
        flex items-center justify-center"
        >
          <div class="w-full h-100">
            <h1 class="text-xl md:text-2xl font-bold leading-tight mt-12">
              Sign In
            </h1>

            <div class="mt-6">
              <div>
                <label class="block text-gray-700">Email Address</label>
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Enter Email Address"
                  class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                  autofocus
                  autocomplete
                  required
                />
              </div>

              <div class="mt-4">
                <label class="block text-gray-700">Password</label>
                <input
                  type="password"
                  onChange={(e) => setpassword(e.target.value)}
                  value={password}
                  placeholder="Enter Password"
                  minlength="6"
                  class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500
                focus:bg-white focus:outline-none"
                  required
                />
              </div>

              <div class="text-right mt-2">
                <button class="text-sm font-semibold text-gray-700 hover:text-blue-700 focus:text-blue-700">
                  Forgot Password?
                </button>
              </div>

              <button
                type="submit"
                onClick={signinUser}
                class="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg
              px-4 py-3 mt-6"
              >
                Log In
              </button>
            </div>

            <hr class="my-6 border-gray-300 w-full" />
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
}

export default Signin;
