import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "../Firebase";
import Footer from "../Footer";
import Nav from "../Nav";
import { motion } from "framer-motion";
import star from "../user/images/star.png";
import musiclogo from "../user/images/musiclogo.png";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { Helmet } from "react-helmet";
function Singing() {
  const [actingPackages, setActingPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    course: "select course",
  });
  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Form Data"), formData);
      // Success! Data is saved to Firestore.
      toast("Form data saved");
      // Reset the form
      setFormData({
        name: "",
        email: "",
        number: "",
        course: "select course",
      });
    } catch (error) {
      // Handle error
      toast("Error saving form data: ", error);
    }
  };
  useEffect(() => {
    const fetchActingPackages = async () => {
      try {
        const packagesRef = collection(db, "packages");
        const q = query(packagesRef, where("course", "==", "Singing"));
        const querySnapshot = await getDocs(q);

        const actingPackageData = querySnapshot.docs.map((doc) => doc.data());
        const sortedActingPackages = actingPackageData.sort(
          (a, b) => a.amount - b.amount
        );
        setActingPackages(sortedActingPackages);
        setLoading(false);
      } catch (error) {
        toast("Error fetching packages:", error);
        setError("Error fetching packages");
        setLoading(false);
      }
    };

    fetchActingPackages();
    const fetchImages = async () => {
      const storage = getStorage();
      const coursesRef = ref(storage, "courses");
      const actingRef = ref(coursesRef, "Singing");

      try {
        const imageList = await listAll(actingRef);
        const imageURLs = await Promise.all(
          imageList.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            // console.log(downloadURL);
            return downloadURL;
          })
        );
        setImages(imageURLs);
        // console.log(imageURLs);
      } catch (error) {
        toast("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div>
      <Nav />
      <Helmet>
        <title>Best Singing Classes in Ahmedabad | Best Music Classes</title>
        <meta
          name="description"
          content="The KK Institute is the best singing classes in ahmedabad. We offer two singing programs: one for aspiring singers' voice training and another for actors, offering holistic benefits in various aspects"
        />
      </Helmet>
      <section className="relative h-screen overflow-hidden">
        {images.map((imageUrl, index) => (
          <motion.img
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={imageUrl}
            alt={`Image ${index}`}
          />
        ))}
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div className="container mx-auto h-full flex items-center justify-center">
          <motion.div
            initial={{ opacity: 1, y: -20 }}
            animate={{ opacity: 1, y: 20 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="text-center text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              Unlock Your Voice
            </h1>
            <p className="text-lg md:text-2xl mb-6">
              Ignite Your Soul: Singing, the Melody of Joy!
            </p>
          </motion.div>
        </div>
      </section>
      <h2 className="my-5 text-center text-5xl font-bold">
        <span className="text-red-500">Best</span>
        Singing Classes in Ahmedabad
      </h2>
      <section className="bg-white">
        <div className="container mx-auto flex flex-wrap items-center">
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-2/3 mb-8 md:mb-0"
          >
            <div className="px-8">
              <h2 className="text-4xl font-bold mb-4">
                A Professional Singer or Singing for Vocal Health
              </h2>
              <p className="text-gray-600 mb-8">
                THE KK INSTITUTE is the best singing classes in ahmedabad. Our
                Singing course comprises two distinct programs: the first is
                tailored to support aspiring singers in achieving their goals
                through comprehensive voice training. The second program is
                designed for actors, providing them with a holistic approach
                that encompasses physical, psychological, social, musical and
                educational benefits.
              </p>
              <p className="text-gray-600 mb-8">
                THE KK INSTITUTE is the top singing classes in ahmedabad. The
                voice stands as one of the paramount instruments at an actor's
                disposal, infusing depth and quite literally, revitalising the
                words on the page. However, dedicating time and energy to voice
                training isn't solely the distinction between a commendable
                performance and a spine-tingling, awe-inspiring masterpiece. It
                holds immense importance for actors' vocal well-being, impacting
                not only their on-stage delivery but also their everyday lives.
              </p>
              <p className="text-gray-600 mb-8">
                The ability to produce breathtaking sounds, attain ideal vocal
                tones, achieve perfect pitch and develop strong vocal muscles
                isn't necessarily an innate gift. Instead, these qualities can
                be cultivated and honed through diligent practice and learning.
              </p>
              <p className="text-gray-600 mb-8">
                Singing is a very crucial part when it comes to acting. Singing
                helps an actor in many ways. Actors can take singing lessons for
                several reasons. First, singing improves your voice.In contrast
                to various other forms of exercise, singing excels in its
                capacity to draw in oxygen and enhance circulation.
              </p>
              <p className="text-gray-600 mb-8">
                Second, singing can help you to improve your posture. As an
                added bonus, it’s a great way to de-stress! Third, singing helps
                you to improve your self-confidence. THE KK INSTITUTE is the
                best music classes in ahmedabad.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3 px-8"
          >
            <form onSubmit={handleSubmit}>
              {/* <!-- component --> */}
              <div className="min-h-screen  py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                  <div className="absolute inset-0 bg-gradient-to-r from-red-300 to-red-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                  <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                      <div>
                        <h2 className="text-2xl font-semibold">Enroll Now</h2>
                      </div>
                      <div className="divide-y divide-gray-200">
                        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="name"
                            />
                            <label
                              htmlFor="name"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Name
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Email address"
                            />
                            <label
                              htmlFor="email"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Email Address
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="number"
                              name="number"
                              type="number"
                              value={formData.number}
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Password"
                            />
                            <label
                              htmlFor="number"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Phone
                            </label>
                          </div>
                          <div className="relative">
                            <select
                              value={formData.course}
                              name="course"
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-600 focus:outline-none focus:borer-rose-600"
                            >
                              <option>Select Course</option>
                              <option>Complete Acting Course</option>
                              <option>Acting</option>
                              <option>Film Acting Course</option>
                              <option>Child Acting</option>
                              <option>Mix Martial Arts</option>
                              <option>Photoshoot</option>
                              <option>Dancing</option>
                              <option>Modelling</option>
                              <option>Grooming</option>
                              <option>Singing</option>
                              <option>Yoga</option>
                              <option>Zumba</option>
                            </select>
                            {/* <label htmlFor="number" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">phone</label> */}
                          </div>

                          <div className="relative">
                            <button className="bg-red-500 text-white rounded-md px-2 py-1">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </section>
      <section className="bg-white ">
        <div className="container px-6 py-10 mx-auto">
          <div className="text-center">
            <h2 className="text-2xl font-semibold capitalize lg:text-3xl">
              Benefits of Singing
            </h2>
            <div className="">
              <span className="inline-block w-40 h-1 bg-red-700 rounded-full"></span>
              <span className="inline-block w-3 h-1 ml-1 bg-red-700 rounded-full"></span>
              <span className="inline-block w-1 h-1 ml-1 bg-red-700 rounded-full"></span>
            </div>
          </div>
        </div>
      </section>
      <ul className="p-4 lg:p-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3  ">
        <li>
          <article>
            <div
              rel="noopener noreferrer"
              href="#"
              className="p-4 overflow-hidden md:grid-cols-5 rounded-xl lg:p-6 flex "
            >
              <img
                src={star}
                className="row-start-1 mb-1 md:col-start-1 xl:col-span-2 w-10 h-10 "
              ></img>
              <h3 className="mb-1 ml-8 font-semibold md:col-start-2 md:col-span-4 md:ml-0 xl:col-start-3 xl:col-span-9">
                Improve Your Voice.
              </h3>
            </div>
          </article>
        </li>
        <li>
          <article>
            <div
              rel="noopener noreferrer"
              href="#"
              className=" p-4 overflow-hidden md:grid-cols-5 rounded-xl lg:p-6 flex "
            >
              <img
                src={star}
                className="row-start-1 mb-1 md:col-start-1 xl:col-span-2 w-10 h-10 "
              ></img>
              <h3 className="mb-1 ml-8 font-semibold md:col-start-2 md:col-span-4 md:ml-0 xl:col-start-3 xl:col-span-9">
                Connect with Your Breath
              </h3>
            </div>
          </article>
        </li>
        <li>
          <article>
            <div
              rel="noopener noreferrer"
              href="#"
              className=" p-4 overflow-hidden md:grid-cols-5 rounded-xl lg:p-6 flex "
            >
              <img
                src={star}
                className="row-start-1 mb-1 md:col-start-1 xl:col-span-2 w-10 h-10 "
              ></img>
              <h3 className="mb-1 ml-8 font-semibold md:col-start-2 md:col-span-4 md:ml-0 xl:col-start-3 xl:col-span-9">
                Enhance Oral Posture
              </h3>
            </div>
          </article>
        </li>
        <li>
          <article>
            <div
              rel="noopener noreferrer"
              href="#"
              className=" p-4 overflow-hidden md:grid-cols-5 rounded-xl lg:p-6 flex "
            >
              <img
                src={star}
                className="row-start-1 mb-1 md:col-start-1 xl:col-span-2 w-10 h-10 "
              ></img>
              <h3 className="mb-1 ml-8 font-semibold md:col-start-2 md:col-span-4 md:ml-0 xl:col-start-3 xl:col-span-9">
                Build a Healthier Voice
              </h3>
            </div>
          </article>
        </li>
        <li>
          <article>
            <div
              rel="noopener noreferrer"
              href="#"
              className=" p-4 overflow-hidden md:grid-cols-5 rounded-xl lg:p-6 flex "
            >
              <img
                src={star}
                className="row-start-1 mb-1 md:col-start-1 xl:col-span-2 w-10 h-10 "
              ></img>
              <h3 className="mb-1 ml-8 font-semibold md:col-start-2 md:col-span-4 md:ml-0 xl:col-start-3 xl:col-span-9">
                Improve Speaking Voice
              </h3>
            </div>
          </article>
        </li>
        <li>
          <article>
            <div
              rel="noopener noreferrer"
              href="#"
              className=" p-4 overflow-hidden md:grid-cols-5 rounded-xl lg:p-6 flex "
            >
              <img
                src={star}
                className="row-start-1 mb-1 md:col-start-1 xl:col-span-2 w-10 h-10 "
              ></img>
              <h3 className="mb-1 ml-8 font-semibold md:col-start-2 md:col-span-4 md:ml-0 xl:col-start-3 xl:col-span-9">
                Memorize Lines
              </h3>
            </div>
          </article>
        </li>
        <li>
          <article>
            <div
              rel="noopener noreferrer"
              href="#"
              className=" p-4 overflow-hidden md:grid-cols-5 rounded-xl lg:p-6 flex "
            >
              <img
                src={star}
                className="row-start-1 mb-1 md:col-start-1 xl:col-span-2 w-10 h-10 "
              ></img>
              <h3 className="mb-1 ml-8 font-semibold md:col-start-2 md:col-span-4 md:ml-0 xl:col-start-3 xl:col-span-9">
                Voice-over Skills
              </h3>
            </div>
          </article>
        </li>
        <li>
          <article>
            <div
              rel="noopener noreferrer"
              href="#"
              className=" p-4 overflow-hidden md:grid-cols-5 rounded-xl lg:p-6 flex "
            >
              <img
                src={star}
                className="row-start-1 mb-1 md:col-start-1 xl:col-span-2 w-10 h-10 "
              ></img>
              <h3 className="mb-1 ml-8 font-semibold md:col-start-2 md:col-span-4 md:ml-0 xl:col-start-3 xl:col-span-9">
                Enhance Social Connection
              </h3>
            </div>
          </article>
        </li>
        <li>
          <article>
            <div
              rel="noopener noreferrer"
              href="#"
              className=" p-4 overflow-hidden md:grid-cols-5 rounded-xl lg:p-6 flex "
            >
              <img
                src={star}
                className="row-start-1 mb-1 md:col-start-1 xl:col-span-2 w-10 h-10 "
              ></img>
              <h3 className="mb-1 ml-8 font-semibold md:col-start-2 md:col-span-4 md:ml-0 xl:col-start-3 xl:col-span-9">
                Improve Performance & Interpretation
              </h3>
            </div>
          </article>
        </li>
      </ul>
      <section className="dark:bg-black dark:text-gray-100">
        <div className="container mx-auto flex flex-col p-6">
          <section className=" ">
            <div className="container px-6 py-10 mx-auto">
              <div className="text-center">
                <h2 className="text-2xl font-semibold capitalize lg:text-3xl">
                  Reasons You Should Add Singing To Your Acting Training Regimen
                </h2>
                <div className="">
                  <span className="inline-block w-40 h-1 bg-red-700 rounded-full"></span>
                  <span className="inline-block w-3 h-1 ml-1 bg-red-700 rounded-full"></span>
                  <span className="inline-block w-1 h-1 ml-1 bg-red-700 rounded-full"></span>
                </div>
              </div>
            </div>
          </section>

          <div className="divide-y divide-gray-700">
            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                <img src={musiclogo} className="w-16 h-16"></img>
              </div>
              <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                {/* <span className="text-2xl tracking-wider uppercase dark:text-red-700">1</span> */}
                <span className="text-xl font-bold md:text-2xl">
                  {" "}
                  Introduction to Music.
                </span>
                <span className="mt-4 dark:text-gray-300">
                  Learn the basics of music theory and musical concepts to
                  enhance your overall understanding of singing.
                </span>
              </div>
            </div>
            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                <img src={musiclogo} className="w-16 h-16"></img>
              </div>
              <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                {/* <span className="text-2xl tracking-wider uppercase dark:text-red-700">2</span> */}
                <span className="text-xl font-bold md:text-2xl">
                  Breathing Exercises.
                </span>
                <span className="mt-4 dark:text-gray-300">
                  Practice breathing exercises that can help you improve breath
                  control and vocal performance.
                </span>
              </div>
            </div>
            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                <img src={musiclogo} className="w-16 h-16"></img>
              </div>
              <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                {/* <span className="text-2xl tracking-wider uppercase dark:text-red-700">3</span> */}
                <span className="text-xl font-bold md:text-2xl">Diction</span>
                <span className="mt-4 dark:text-gray-300">
                  Learn how to articulate words clearly and effectively to
                  convey emotions and meaning in your singing.
                </span>
              </div>
            </div>
            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                <img src={musiclogo} className="w-16 h-16"></img>
              </div>
              <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                {/* <span className="text-2xl tracking-wider uppercase dark:text-red-700">4</span> */}
                <span className="text-xl font-bold md:text-2xl">
                  Understanding Notes
                </span>
                <span className="mt-4 dark:text-gray-300">
                  Gain knowledge of musical notes, scales, and their application
                  in singing.
                </span>
              </div>
            </div>
            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                <img src={musiclogo} className="w-16 h-16"></img>
              </div>
              <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                {/* <span className="text-2xl tracking-wider uppercase dark:text-red-700">5</span> */}
                <span className="text-xl font-bold md:text-2xl">
                  Major, Minor Scale of Sare Ga Ma
                </span>
                <span className="mt-4 dark:text-gray-300">
                  Learn the major and minor scales of Indian classNameical
                  music, including Sare, Ga, and Ma.
                </span>
              </div>
            </div>
            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                <img src={musiclogo} className="w-16 h-16"></img>
              </div>
              <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                {/* <span className="text-2xl tracking-wider uppercase dark:text-red-700">6</span> */}
                <span className="text-xl font-bold md:text-2xl">
                  Practice of Low, Middle & High Notes
                </span>
                <span className="mt-4 dark:text-gray-300">
                  Develop your vocal range by practicing low, middle, and high
                  notes, expanding your vocal abilities
                </span>
              </div>
            </div>
            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                <img src={musiclogo} className="w-16 h-16"></img>
              </div>
              <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                {/* <span className="text-2xl tracking-wider uppercase dark:text-red-700">7</span> */}
                <span className="text-xl font-bold md:text-2xl">
                  Singing different Raags (raga)
                </span>
                <span className="mt-4 dark:text-gray-300">
                  Learn and practice singing various Indian classNameical music
                  raags (ragas) to expand your repertoire and improve your vocal
                  versatility.
                </span>
              </div>
            </div>
            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                <img src={musiclogo} className="w-16 h-16"></img>
              </div>
              <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                {/* <span className="text-2xl tracking-wider uppercase dark:text-red-700">8</span> */}
                <span className="text-xl font-bold md:text-2xl">
                  Voice Modulation according to the Sur
                </span>
                <span className="mt-4 dark:text-gray-300">
                  Learn how to modulate your voice based on the pitch (sur) of
                  the song, adding expression and emotion to your singing.
                </span>
              </div>
            </div>
            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                <img src={musiclogo} className="w-16 h-16"></img>
              </div>
              <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                {/* <span className="text-2xl tracking-wider uppercase dark:text-red-700">9</span> */}
                <span className="text-xl font-bold md:text-2xl">
                  Stage Performance & Improvisation
                </span>
                <span className="mt-4 dark:text-gray-300">
                  Develop stage presence, performance skills, and improvisation
                  techniques to enhance your live performances.
                </span>
              </div>
            </div>
            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                <img src={musiclogo} className="w-16 h-16"></img>
              </div>
              <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                {/* <span className="text-2xl tracking-wider uppercase dark:text-red-700">4</span> */}
                <span className="text-xl font-bold md:text-2xl">
                  Mike Usage and Studio Visits
                </span>
                <span className="mt-4 dark:text-gray-300">
                  Gain knowledge of musical notes, scales, and their application
                  in singing.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mt-5 h-full mb-20 flex flex-col justify-center items-center px-4 lg:px-0">
        <div className="w-full max-w-3xl mx-auto">
          <div className="text-center font-semibold">
            <h1 className="text-3xl md:text-5xl">
              <span className="text-red-700 tracking-wide">Singing </span>
              <span>packages</span>
            </h1>
            <p className="pt-4 md:pt-6 text-base md:text-xl text-gray-400 font-normal">
              Choose a plan that works best for you
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between pt-8 md:pt-24">
            {actingPackages.map((p) => (
              <div
                key={p.packageType}
                className={`w-full md:w-1/3 p-4 md:p-8 even:bg-black even:text-white text-center rounded-3xl shadow-xl`}
              >
                <h2 className="text-lg md:text-2xl font-semibold">
                  {p.packageType}
                </h2>
                <p className="pt-2 tracking-wide">
                  <span className="text-gray-400 align-top">₹ </span>
                  <span className="text-xl md:text-3xl font-semibold">
                    {p.amount}
                  </span>
                </p>
                <hr className="mt-4 border-1" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default Singing;
