import React from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import Signin from "./Signin";
import pricingadmin from "../user/images/pricingadmin.png";
import logoutadmin from "../user/images/logoutadmin.png";
import coursesadmin from "../user/images/coursesadmin.png";
import imageadmin from "../user/images/imageadmin.png";
import banneradmin from "../user/images/banneradmin.png";
import dashboardadmin from "../user/images/dashboardadmin.png";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { app } from "../Firebase";
import Images2 from "./Images2";
const auth = getAuth(app);
function Images() {
  const [user, setUser] = useState(null);
  const [courseImages, setCourseImages] = useState([]);
  const [progress, setProgress] = useState(0);
  const handleImageChange = (e, index) => {
    const files = e.target.files;
    if (files && files[0]) {
      const updatedImages = [...courseImages];
      updatedImages[index] = files[0];
      setCourseImages(updatedImages);
    }
  };

  const handleUpload = async (courseName, image) => {
    try {
      const storage = getStorage();
      const storageRef = ref(storage, `courses/${courseName}/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Handle upload progress if needed
          const uploadProgress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          toast(`Upload progress for ${courseName}: ${uploadProgress}%`);
          setProgress(uploadProgress);
        },
        (error) => {
          // Handle upload error
          toast(`Error uploading ${courseName} image: ${error.message}`);
        },
        () => {
          // Handle upload completion
          toast(`${courseName} image uploaded successfully.`);
        }
      );
    } catch (error) {
      toast("Error uploading image:", error);
    }
  };
  const handleDelete = async (courseName, imageName) => {
    try {
      const storage = getStorage();
      const storageRef = ref(storage, `courses/${courseName}/${imageName}`);
      await deleteObject(storageRef);
      toast(`${courseName} image deleted successfully.`);
    } catch (error) {
      toast(`Error deleting ${courseName} image: ${error.message}`);
    }
  };
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // yes you are logged in
        setUser(user);
      } else {
        // user is logged out
        setUser(null);
      }
    });
  }, []);

  if (user === null) {
    return (
      <div>
        <Signin />
      </div>
    );
  }
  const courses = [
    "Acting",
    "Complete Acting Course",
    "Dance",
    "Grooming",
    "Modelling",
    "Protfolio",
    "Singing",
    "Yoga",
    "Zumba",
    "MMA",
    "Child Acting",
    "Film Acting",
    // Add more course names here
  ];
  return (
    <div>
      <div class="min-h-screen w-full flex overflow-hidden select-none">
        <nav class="w-16 sm:w-24 flex flex-col items-center bg-white dark:bg-white py-4">
          <div>
            <img class="h-6 sm:h-8 w-6 sm:w-8 fill-current tered-600 dark:text-red-300" />
          </div>

          <ul class="mt-2 text-gray-700 dark:text-gray-400 capitalize">
            <li class="mt-3 p-2  rounded-lg">
              <a href="/FormData" class="flex flex-col items-center">
                <img src={dashboardadmin} class="h-5 w-5" alt="Dashboard" />
                <span class="text-xs text-black mt-2">Form Data</span>
              </a>
            </li>

            <li class="mt-3 p-2  hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Banner" class="flex flex-col items-center">
                <img src={banneradmin} class="h-5 w-5" alt="Banner" />
                <span class="text-xs text-black  mt-2">Banner</span>
              </a>
            </li>

            <li class="mt-3 p-2  text-red-600 dark:text-red-300 hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Images" class="flex flex-col items-center">
                <img src={imageadmin} class="h-5 w-5" alt="Image" />
                <span class="text-xs  mt-2">Image</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Gallery" class="flex flex-col items-center">
                <img src={coursesadmin} class="h-5 w-5" alt="Courses" />
                <span class="text-xs text-black mt-2">Gallery</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600  rounded-lg">
              <a href="/Pricing" class="flex flex-col items-center">
                <img src={pricingadmin} class="h-5 w-5" alt="Pricing" />
                <span class="text-xs text-black  mt-2">Pricing</span>
              </a>
            </li>
          </ul>

          <div class="mt-auto flex items-center p-2 text-red-700 bg-gray-200 dark:text-red-500 rounded-full">
            <button onClick={() => signOut(auth)}>
              <img src={logoutadmin} class="h-5 w-5" alt="Logout" />
            </button>
          </div>
        </nav>

        <main className="my-1 pt-2 pb-2 px-2 sm:px-10 flex-1 bg-gray-200 rounded-l-lg transition duration-500 ease-in-out overflow-y-auto">
          <div className="container mx-auto">
          <h1 className="my-5 text-center text-3xl font-bold">
            <span className="text-red-500">Courses</span> Image
          </h1>
            <div className="grid grid-cols-2 gap-4">
              {courses.map((course, index) => (
                <div key={course} className="p-4 bg-gray-100 rounded">
                  <h2 className="text-xl font-bold mb-2">{course}</h2>
                  <input
                    type="file"
                    onChange={(e) => handleImageChange(e, index)}
                    className="mb-2"
                  />
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => handleUpload(course, courseImages[index])}
                  >
                    Upload
                  </button>
                  <progress
                    className="w-full h-2 bg-red-200 mt-2"
                    value={progress}
                    max="100"
                  />
                  {courseImages[index] && (
                    <div className="mt-2">
                      <p className="text-sm font-medium">Uploaded Image:</p>
                      <div className="flex items-center space-x-2">
                        <img
                          src={URL.createObjectURL(courseImages[index])}
                          alt={course}
                          className="w-12 h-12 rounded"
                        />
                        <button
                          className="text-red-500 hover:text-red-700 font-medium"
                          onClick={() =>
                            handleDelete(course, courseImages[index].name)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <Images2/>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Images;
