import React from "react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../Firebase";
import { motion } from "framer-motion";
import Signin from "./Signin";
import pricingadmin from "../user/images/pricingadmin.png";
import logoutadmin from "../user/images/logoutadmin.png";
import coursesadmin from "../user/images/coursesadmin.png";
import imageadmin from "../user/images/imageadmin.png";
import banneradmin from "../user/images/banneradmin.png";
import dashboardadmin from "../user/images/dashboardadmin.png";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { app } from "../Firebase";
import GalleryDelete from "./GalleryDelete";
const auth = getAuth(app);
function Gallery() {
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");

  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // yes you are logged in
        setUser(user);
      } else {
        // user is logged out
        setUser(null);
      }
    });
  }, []);
  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Upload the image to Firebase Storage
    const storageRef = ref(storage, image.name);
    await uploadBytes(storageRef, image);

    // Get the image URL
    const imageUrl = await getDownloadURL(storageRef);

    // Save the image details in Firestore
    const imagesRef = collection(db, "Gallery");
    const imageDoc = await addDoc(imagesRef, {
      imageUrl,
      description,
      youtubeLink,
    });
    toast("Image added successfully");

    // Reset the form
    setImage(null);
    setDescription("");
    setYoutubeLink("");
  };

  if (user === null) {
    return (
      <div>
        <Signin />
      </div>
    );
  }
  return (
    <div>
      <div class="min-h-screen w-full flex overflow-hidden select-none">
        <nav class="w-16 sm:w-24 flex flex-col items-center bg-white dark:bg-white py-4">
          <div>
            <img class="h-6 sm:h-8 w-6 sm:w-8 fill-current text-red-600 dark:text-red-300" />
          </div>

          <ul class="mt-2 text-gray-700 dark:text-gray-400 capitalize">
            <li class="mt-3 p-2  rounded-lg">
              <a href="/FormData" class="flex flex-col items-center">
                <img src={dashboardadmin} class="h-5 w-5" alt="Dashboard" />
                <span class="text-xs text-black mt-2">Form Data</span>
              </a>
            </li>

            <li class="mt-3 p-2  hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Banner" class="flex flex-col items-center">
                <img src={banneradmin} class="h-5 w-5" alt="Banner" />
                <span class="text-xs text-black  mt-2">Banner</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Images" class="flex flex-col items-center">
                <img src={imageadmin} class="h-5 w-5" alt="Image" />
                <span class="text-xs text-black mt-2">Image</span>
              </a>
            </li>

            <li class="mt-3 p-2  text-red-600 dark:text-red-300 hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Gallery" class="flex flex-col items-center">
                <img src={coursesadmin} class="h-5 w-5" alt="Courses" />
                <span class="text-xs  mt-2">Gallery</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600  rounded-lg">
              <a href="/Pricing" class="flex flex-col items-center">
                <img src={pricingadmin} class="h-5 w-5" alt="Pricing" />
                <span class="text-xs text-black  mt-2">Pricing</span>
              </a>
            </li>
          </ul>

          <div class="mt-auto flex items-center p-2 text-red-700 bg-gray-200 dark:text-red-500 rounded-full">
            <button onClick={() => signOut(auth)}>
              <img src={logoutadmin} class="h-5 w-5" alt="Logout" />
            </button>
          </div>
        </nav>

        <main className="my-1 pt-2 pb-2 px-2 sm:px-10 flex-1 bg-gray-200 rounded-l-lg transition duration-500 ease-in-out overflow-y-auto">
          <div className="flex items-center justify-center h-96">
            <div className="max-w-md mx-auto">
              <form onSubmit={handleSubmit} className="space-y-4 ">
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <div>
                    <label htmlFor="image" className="block">
                      Image:
                    </label>
                    <input
                      type="file"
                      id="image"
                      onChange={handleImageChange}
                      className="border bg-white border-gray-300 w-80 rounded-lg p-2"
                    />
                  </div>
                  <div>
                    <label htmlFor="description" className="block">
                      Description:
                    </label>
                    <textarea
                      type="text"
                      id="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="border border-gray-300 w-80 rounded-lg p-2"
                    />
                  </div>
                  <div>
                    <label htmlFor="youtubeLink" className="block">
                      YouTube Link:
                    </label>
                    <input
                      type="text"
                      id="youtubeLink"
                      value={youtubeLink}
                      onChange={(e) => setYoutubeLink(e.target.value)}
                      className="border border-gray-300 w-80 rounded-lg p-2"
                    />
                  </div>
                  <motion.button
                    type="submit"
                    className="bg-red-500 text-white mt-5 px-4 py-2 rounded items-center justify-center "
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Add Data
                  </motion.button>
                </motion.div>
              </form>
            </div>
          </div>
          <GalleryDelete />
        </main>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Gallery;
