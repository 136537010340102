import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer";
import Nav from "../Nav";
import { motion } from "framer-motion";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { Helmet } from "react-helmet";
function FilmActingCourse() {
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    course: "select course",
  });
  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Form Data"), formData);
      // Success! Data is saved to Firestore.
      toast("Form data saved ");
      // Reset the form
      setFormData({
        name: "",
        email: "",
        number: "",
        course: "select course",
      });
    } catch (error) {
      // Handle error
      toast("Error saving form data: ", error);
    }
  };
  useEffect(() => {
    const fetchImages = async () => {
      const storage = getStorage();
      const coursesRef = ref(storage, "courses");
      const actingRef = ref(coursesRef, "Film Acting");

      try {
        const imageList = await listAll(actingRef);
        const imageURLs = await Promise.all(
          imageList.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            // console.log(downloadURL);
            return downloadURL;
          })
        );
        setImages(imageURLs);
        // console.log(imageURLs);
      } catch (error) {
        toast("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);
  const peopleList = [
    { id: 1, group: "Observation & Imagination" },
    { id: 2, group: "Accent & Diction " },
    {
      id: 3,
      group: "Script analysis, characterization & body language skills",
    },
    {
      id: 4,
      group:
        "To understand the technicalities of short division, angles and magnification",
    },
    { id: 5, group: "Emotions & Expressions" },
    {
      id: 6,
      group:
        "Techniques of transition of emotions, sense of rhythm, audition preparation, monologues",
    },
    { id: 7, group: "To understand camera and lighting" },
    { id: 8, group: "Safety protocols while shooting" },
    { id: 9, group: "Identifying your strong & weak points" },
    { id: 10, group: "Preparation for and Enactment of scenes" },
    { id: 11, group: "Dubbing, Recording & Voice Modulation" },
    {
      id: 12,
      group:
        "Live studio visits for observation of professional actor performing their crafts",
    },
    { id: 13, group: "Demo film" },
    {
      id: 14,
      group:
        "•	Participation in real shoot to get the feel and hang for subject",
    },
  ];

  return (
    <div>
      <Nav />
      <Helmet>
        <title>Leading Film Acting School in Ahmedabad</title>
        <meta
          name="description"
          content="The KK Institute is leading film acting school in ahmedabad, offering comprehensive training in theatre and film acting"
        />
      </Helmet>
      <section className="relative h-screen overflow-hidden">
        {images.map((imageUrl, index) => (
          <motion.img
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={imageUrl}
            alt={`Image ${index}`}
          />
        ))}
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div className="container mx-auto h-full flex items-center justify-center">
          <motion.div
            initial={{ opacity: 1, y: -20 }}
            animate={{ opacity: 1, y: 20 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="text-center text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              Transform Yourself into a Versatile Actor
            </h1>
            <p className="text-lg md:text-2xl mb-6">
              Join Our Film Acting Course Today!
            </p>
          </motion.div>
        </div>
      </section>
      <h2 className="my-5 text-center text-5xl font-bold">
        <span className="text-red-500">Leading </span>
        Film Acting School in Ahmedabad
      </h2>
      <section className="bg-white ">
        <div className="container mx-auto flex flex-wrap items-center">
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-2/3 mb-8 md:mb-0"
          >
            <div className="px-8">
              <h2 className="text-4xl font-bold mb-4">Film Acting Course</h2>
              <p className="text-gray-600 mb-8">
                THE KK INSTITUTE leading film acting school in ahmedabad
                provides curriculum with effective tools and techniques that
                enable them to grow in the craft. THE KK INSTITUTE drama classes
                aim at offering all our students a broad and balanced curriculum
                that provides rewarding and stimulating activities to prepare
                them for the best social and cultural life. They are trained to
                deliver performances authentically by tapping into their rich
                imagination. Through their training, they acquire the skill to
                express themselves convincingly, harnessing a range of impactful
                and potent emotions.Through a perfect blend of theory,
                practicals, exercises, scene work, performances and discussions,
                aspiring actors are moulded into creative professionals capable
                of performing across platforms in their own unique inimitable
                style. The institute frequently extends invitations to media and
                entertainment industry professionals for engaging interactions
                with our students. THE KK INSTITUTE takes great pride in hosting
                numerous Indian celebrities who have graced our institution with
                their presence and engaged with our actors. Our alumni from THE
                KK INSTITUTE have prominently featured in a wide array of
                advertising commercials, plays, films, television shows and web
                series.
              </p>
              <p className="text-gray-600 mb-8">
                THE KK INSTITUTE provides nurturing and motivational atmospheres
                for inquisitive young minds to thrive and develop alongside us.
                Our mission is to cultivate self-assured and imaginative
                thinkers, delivering an education that holds genuine relevance
                for their future.
              </p>
              <p className="text-gray-600 mb-8">
                Our students graduate with both the character and the confidence
                to leave a lasting impact on the world. They are armed with the
                knowledge and practical skills that propel them far ahead in
                their respective industries, enabling them to excel and make
                significant contributions.
              </p>
              <p className="text-gray-600 mb-8">
                We have an open admission policy that allows anyone to enter our
                programs regardless of previous experience. Our teachers
                specialise in bringing our diverse and eclectic groups of
                students to the peak of their abilities no matter what level
                they are at when they enter.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3 px-8"
          >
            <form onSubmit={handleSubmit}>
              {/* <!-- component --> */}
              <div className="min-h-screen  py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                  <div className="absolute inset-0 bg-gradient-to-r from-red-300 to-red-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                  <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                      <div>
                        <h2 className="text-2xl font-semibold">Enroll Now</h2>
                      </div>
                      <div className="divide-y divide-gray-200">
                        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="name"
                            />
                            <label
                              htmlFor="name"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Name
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Email address"
                            />
                            <label
                              htmlFor="email"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Email Address
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="number"
                              name="number"
                              type="number"
                              value={formData.number}
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Password"
                            />
                            <label
                              htmlFor="number"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Phone
                            </label>
                          </div>
                          <div className="relative">
                            <select
                              value={formData.course}
                              name="course"
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-600 focus:outline-none focus:borer-rose-600"
                            >
                              <option>Select Course</option>
                              <option>Complete Acting Course</option>
                              <option>Acting</option>
                              <option>Film Acting Course</option>
                              <option>Child Acting</option>
                              <option>Mix Martial Arts</option>
                              <option>Photoshoot</option>
                              <option>Dancing</option>
                              <option>Modelling</option>
                              <option>Grooming</option>
                              <option>Singing</option>
                              <option>Yoga</option>
                              <option>Zumba</option>
                            </select>
                            {/* <label htmlFor="number" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">phone</label> */}
                          </div>

                          <div className="relative">
                            <button className="bg-red-500 text-white rounded-md px-2 py-1">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </section>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-8 sm:p-10 text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 mb-4">
              Many of our acting students are:-
            </h2>
            {/* <p className="text-gray-600 mb-4">PACKAGE COST: 20000</p> */}
            <ul className="list-disc  list-inside mb-8">
              <li className="flex items-center mb-2">
                <span className="text-blue-600 mr-2">&#x2713;</span>
                People with little or no acting experience.
              </li>
              <li className="flex items-center mb-2">
                <span className="text-blue-600 mr-2">&#x2713;</span>
                People who have some theater experience but little or no film
                experience
              </li>
              <li className="flex items-center mb-2">
                <span className="text-blue-600 mr-2">&#x2713;</span>
                An eclectic group of people with diverse backgrounds, from all
                over the world.
              </li>
              <li className="flex items-center mb-2">
                <span className="text-blue-600 mr-2">&#x2713;</span>
                Colleges & schools dramatics & theatre teams.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center h-full pb-4 bg-white">
        <h2 className="text-3xl font-bold mb-8">Acting Goals:</h2>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {peopleList.map((person) => (
            <motion.li
              key={person.id}
              className="bg-white p-4 rounded-md"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 * person.id }}
            >
              {person.group}
            </motion.li>
          ))}
        </ul>
      </div>

      <Footer />
      <ToastContainer />
    </div>
  );
}

export default FilmActingCourse;
