import { useEffect, useState } from 'react';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../Firebase';
import Nav from '../Nav';
import Footer from '../Footer';
import { motion } from 'framer-motion';
function EventNews() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const imagesRef = collection(db, 'Gallery');
    const q = query(imagesRef);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const imageList = snapshot.docs.map((doc) => doc.data());
      setImages(imageList);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div>
  <Nav />
  <h1 className="my-5 text-center text-3xl font-bold"><span className='text-red-500'>Event</span> Gallery</h1>
  <motion.div
    className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-5"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
  >
    {images.map((image) => (
      <motion.div
        key={image.imageUrl}
        className="bg-white rounded-lg p-4 shadow-md"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <img src={image.imageUrl} alt={image.description} className="w-full h-auto" />
        <h3 className="mt-2 text-lg font-semibold">Description: </h3>
        <p className="mt-1 text-gray-800">{image.description}</p>
        <a
          href={image.youtubeLink}
          target="_blank"
          rel="noopener noreferrer"
          className="text-red-500 text-center hover:underline mt-2 block "
        >
          Watch Video
        </a>
      </motion.div>
    ))}
  </motion.div>

  <Footer />
</div>
  );
}

export default EventNews;
