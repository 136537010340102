import React from "react";
import { motion } from "framer-motion";
import Nav from "../Nav";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
// import WELCOME from "./images/hero.png"
const WhatYouCanLearn = () => {
  // const  WELCOME = require("./images/WELCOME.mp4").default;
  // const  = "./images/WELCOME.mp4"
  const topics = [
    "Acting",
    "Complete Idea about Film Making",
    "Camera Facing",
    "Navras (Nine Emotions)",
    "Accent",
    "Diction",
    "Body Language",
    "Workshops under the guidance of celebrities",
    "Safety protocols while shooting",
    "Identifying your strong & weak points",
    "Stage practice",
    "Pre-production",
    "Post-production",
    "Observation & Imagination",
    "Personality Development",
    "Dialogue Delivery",
    "Voice Modulation",
    "Voice Recording/Dubbing",
    "Audition Techniques",
    "Knowledge About Acting Industry",
    "Basic part of Camera Knowledge",
    "Basic part of Direction",
    "Basic part of Script Writing",
    "Body Toning",
    "Yoga",
    "Dance",
    "Zumba",
  ];
  return (
    <div
      className={`bg-cover bg-center h-full`}
      // style={{ backgroundImage: `url('${WELCOME}')` }}
    >
      <Nav />
      <Helmet>
        <title>What you can learn from Best Acting Classes</title>
        <meta
          name="description"
          content="We Provide wide range of valuable skills and insights that can help you become a more effective and accomplished actor."
        />
      </Helmet>
      <div className="flex flex-col items-center py-5 px-2 justify-center h-full bg-gray-100">
        <h1 className="my-5 text-center text-3xl font-bold">
          <span className="text-red-500">What You Can</span> Learn?
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {topics.map((topic, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg p-6 shadow-md flex items-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 * index }}
            >
              <div className="mr-4">
                <span className="text-2xl text-red-500">&#8226;</span>
              </div>
              <div>
                <h2 className="text-lg font-semibold mb-2">{topic}</h2>
                <p className="text-gray-600">{/* Add description here */}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WhatYouCanLearn;
