import React from "react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { storage } from "../Firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  listAll,
  deleteObject,
} from "firebase/storage";
import Signin from "./Signin";
import pricingadmin from "../user/images/pricingadmin.png";
import logoutadmin from "../user/images/logoutadmin.png";
import coursesadmin from "../user/images/coursesadmin.png";
import imageadmin from "../user/images/imageadmin.png";
import banneradmin from "../user/images/banneradmin.png";
import dashboardadmin from "../user/images/dashboardadmin.png";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { app } from "../Firebase";
const auth = getAuth(app);
function Banner() {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [progress, setProgress] = useState(0);

  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // yes you are logged in
        setUser(user);
      } else {
        // user is logged out
        setUser(null);
      }
    });
    const fetchImages = async () => {
      const storageRef = ref(storage, "images");
      const imageList = await listAll(storageRef);
      const urls = await Promise.all(
        imageList.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return { url, ref: itemRef };
        })
      );
      setImages(urls);
    };

    fetchImages();
  }, []);
  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const handleUpload = () => {
    if (selectedImage) {
      const storageRef = ref(storage, `images/${selectedImage.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedImage);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const uploadProgress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(uploadProgress);
        },
        (error) => {
          toast(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setImages((prevImages) => [
              ...prevImages,
              { url, ref: uploadTask.snapshot.ref },
            ]);
            setSelectedImage(null);
          });
        }
      );
    }
  };
  const handleDelete = async (imageRef) => {
    try {
      await deleteObject(imageRef);
      setImages((prevImages) =>
        prevImages.filter((image) => image.ref !== imageRef)
      );
      toast("Image deleted successfully.");
    } catch (error) {
      toast("Error deleting image:", error);
    }
  };
  if (user === null) {
    return (
      <div>
        <Signin />
      </div>
    );
  }
  return (
    <div>
      <div class="min-h-screen w-full flex overflow-hidden select-none">
        <nav class="w-16 sm:w-24 flex flex-col items-center bg-white dark:bg-white py-4">
          <div>
            <img class="h-6 sm:h-8 w-6 sm:w-8 fill-current text-red-600 dark:text-red-300" />
          </div>

          <ul class="mt-2 text-gray-700 dark:text-gray-400 capitalize">
            <li class="mt-3 p-2  rounded-lg">
              <a href="/FormData" class="flex flex-col items-center">
                <img src={dashboardadmin} class="h-5 w-5" alt="Dashboard" />
                <span class="text-xs text-black mt-2">Form Data</span>
              </a>
            </li>

            <li class="mt-3 p-2  text-red-600 dark:text-red-300 hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Banner" class="flex flex-col items-center">
                <img src={banneradmin} class="h-5 w-5" alt="Banner" />
                <span class="text-xs  mt-2">Banner</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Images" class="flex flex-col items-center">
                <img src={imageadmin} class="h-5 w-5" alt="Image" />
                <span class="text-xs text-black mt-2">Image</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Gallery" class="flex flex-col items-center">
                <img src={coursesadmin} class="h-5 w-5" alt="Courses" />
                <span class="text-xs text-black mt-2">Gallery</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600  rounded-lg">
              <a href="/Pricing" class="flex flex-col items-center">
                <img src={pricingadmin} class="h-5 w-5" alt="Pricing" />
                <span class="text-xs text-black  mt-2">Pricing</span>
              </a>
            </li>
          </ul>

          <div class="mt-auto flex items-center p-2 text-red-700 bg-gray-200 dark:text-red-500 rounded-full">
            <button onClick={() => signOut(auth)}>
              <img src={logoutadmin} class="h-5 w-5" alt="Logout" />
            </button>
          </div>
        </nav>

        <main className="my-1 pt-2 pb-2 px-2 sm:px-10 flex-1 bg-gray-200 rounded-l-lg transition duration-500 ease-in-out overflow-y-auto">
          <div>
          <h1 className="my-5 text-center text-3xl font-bold">
            <span className="text-red-500">Slider</span> Image
          </h1>
            <div className="flex flex-col sm:flex  items-center space-y-4">
            <div className="">
            <input type="file" className="mb-5" onChange={handleImageChange} />
            <button
              className="bg-red-500 mb-5  hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleUpload}
            >
              Upload
            </button>
            <progress
              className="w-full h-2 bg-red-200 mt-2"
              value={progress}
              max="100"
            />
            </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {images.map((image) => (
                <div
                  key={image.url}
                  className="bg-gray-200 rounded-lg p-4 transition duration-500 ease-in-out"
                >
                  <img
                    className="w-full h-40 object-cover rounded"
                    src={image.url}
                    alt="Uploaded"
                  />
                 
                  <div className="flex justify-center mt-2">
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                      onClick={() => handleDelete(image.ref)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default Banner;
