import { useState } from "react";
import logo from "./user/images/mainLogo.jpeg";
const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [coursesOpen, setCoursesOpen] = useState(false);

  return (
    <nav className="bg-black ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center h-16">
            <div className="flex-shrink-0">
              <a href="/">
                <img className="h-16 w-32" src={logo} alt="Workflow" />
              </a>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <button className="text-gray-300 hover:bg-red-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  <a href="/"> Home </a>
                </button>
                <div className="relative">
                  <button
                    className="text-gray-300 hover:bg-red-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    onClick={() => {
                      setAboutOpen(!aboutOpen);
                      setCoursesOpen(false);
                    }}
                  >
                    About
                    <span
                      className={`${
                        aboutOpen ? "transform rotate-180" : ""
                      } ml-1 inline-block`}
                    >
                      <svg
                        className="h-4 w-4 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L11 6.414V16a1 1 0 01-2 0V6.414l-1.293 1.293a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </button>
                  {aboutOpen && (
                    <div
                      className="absolute z-50
                      top-full left-0 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                    >
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/KKInstitute">About KK Institute</a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/WhyWeAreNo1">Why We Are No.1 ?</a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/AboutNetrixEntertainment">
                            About Netrix Entertainment
                          </a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/WhatYouCanLearn">What You Can Learn?</a>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <button
                    className="text-gray-300 hover:bg-red-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    onClick={() => {
                      setCoursesOpen(!coursesOpen);
                      setAboutOpen(false);
                    }}
                  >
                    Courses
                    <span
                      className={`${
                        coursesOpen ? "transform rotate-180" : ""
                      } ml-1 inline-block`}
                    >
                      <svg
                        className="h-4 w-4 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L11 6.414V16a1 1 0 01-2 0V6.414l-1.293 1.293a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </button>
                  {coursesOpen && (
                    <div
                      className="absolute z-50
                      top-full left-0 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                    >
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/best-acting-classes">Acting</a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/diploma-in-acting-complete-acting-course">
                            Complete Acting Course
                          </a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/best-dance-classes">Dance</a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/grooming-and-personal-dvelopment-classes">
                            {" "}
                            Grooming
                          </a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/best-modelling-agency"> Modelling</a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/professional-photoshoot"> Portfolio</a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/singing-classes-music-classes"> Singing</a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/yoga-classes"> Yoga</a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/zumba-classes-zumba-dance-classes">
                            {" "}
                            Zumba
                          </a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/best-martial-arts-classes"> MMA</a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/best-acting-school-for-kids-and-children">
                            Child Acting
                          </a>
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          <a href="/film-acting-school">Film Acting</a>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  className="text-gray-300 hover:bg-red-700 hover:text-white px-3 py-2 rounded-md text
            -sm font-medium"
                >
                  <a href="/Contact">Contact</a>
                </button>
                <button
                  className="text-gray-300 hover:bg-red-700 hover:text-white px-3 py-2 rounded-md text
            -sm font-medium"
                >
                  <a href="/EventNews">Gallery</a>
                </button>
              </div>
            </div>
          </div>
          <div className=" -mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {/* Hamburger Menu */}
              <svg
                className={`${isOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* Close Menu */}
              <svg
                className={`${isOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`${isOpen ? "block" : "hidden"} md:hidden`}
        id="mobile-menu"
      >
        <div className=" px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <button className="text-gray-300 hover:bg-red-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
            <a href="/">Home </a>
          </button>
          <div className="relative">
            <button
              className="text-gray-300 hover:bg-red-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              onClick={() => {
                setAboutOpen(!aboutOpen);
                setCoursesOpen(false);
              }}
            >
              About
              <span
                className={`${
                  aboutOpen ? "transform rotate-180" : ""
                } ml-1 inline-block`}
              >
                <svg
                  className="h-4 w-4 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L11 6.414V16a1 1 0 01-2 0V6.414l-1.293 1.293a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </button>
            {aboutOpen && (
              <div
                className="z-50 absolute top-full left-0 mt-1
        rounded-md bg-white shadow-lg py-1"
              >
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/KKInstitute">About KK Institute</a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/WhyWeAreNo1">Why We Are No.1 ?</a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/AboutNetrixEntertainment">
                    About Netrix Entertainment
                  </a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/WhatYouCanLearn">What You Can Learn?</a>
                </button>
              </div>
            )}
          </div>
          <div className="relative">
            <button
              className="text-gray-300 hover:bg-red-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              onClick={() => {
                setCoursesOpen(!coursesOpen);
                setAboutOpen(false);
              }}
            >
              Courses
              <span
                className={`${
                  coursesOpen ? "transform rotate-180" : ""
                } ml-1 inline-block`}
              >
                <svg
                  className="h-4 w-4 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L11 6.414V16a1 1 0 01-2 0V6.414l-1.293 1.293a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </button>
            {coursesOpen && (
              <div
                className="z-50 absolute top-full left-0 mt-1
        rounded-md bg-white shadow-lg py-1"
              >
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/best-acting-classes">Acting</a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/diploma-in-acting-complete-acting-course">
                    Complete Acting Course
                  </a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/best-dance-classes"> Dance</a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/grooming-and-personal-dvelopment-classes">
                    {" "}
                    Grooming
                  </a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/best-modelling-agency"> Modelling</a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/professional-photoshoot"> Portfolio</a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/singing-classes-music-classes"> Singing</a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/yoga-classes"> Yoga</a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/zumba-classes-zumba-dance-classes"> Zumba</a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/best-martial-arts-classes"> MMA</a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/best-acting-school-for-kids-and-children">
                    {" "}
                    Child Acting
                  </a>
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                  <a href="/film-acting-school">Film Acting</a>
                </button>
              </div>
            )}
          </div>
          <button className="text-gray-300 hover:bg-red-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
            <a href="/Contact">Contact</a>
          </button>
          <button className="text-gray-300 hover:bg-red-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
            <a href="/EventNews">Gallery</a>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
