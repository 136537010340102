import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./user/Home";
// import About from "./user/About";
import WhyWeAreNo1 from "./user/WhyWeAreNo1";
import AboutNetrixEntertainment from "./user/AboutNetrixEntertainment";
import KKInstitute from "./KKInstitute";
import Footer from "./Footer";
import Zumba from "./courses/Zumba";
import Yoga from "./courses/Yoga";
import Dance from "./courses/Dance";
import Singing from "./courses/Singing";
import Modelling from "./courses/Modelling";
import Grooming from "./courses/Grooming";
import Acting from "./courses/Acting";
import MixMartialArts from "./courses/MixMartialArts";
import ChildActing from "./courses/ChildActing";
import WhatYouCanLearn from "./user/WhatYouCanLearn";
import Photoshoot from "./courses/Photoshoot";
import CompleteActingCourse from "./courses/CompleteActingCourse";
import FilmActingCourse from "./courses/FilmActingCourse";
import Contact from "./user/Contact";
// import AdminDashboard from './admin/AdminDashboard'
import Signin from "./admin/Signin";
import Pricing from "./admin/Pricing";
import Banner from "./admin/Banner";
import Images from "./admin/Images";
// import Images2 from './admin/Images2'
import FormData from "./admin/FormData";
import Gallery from "./admin/Gallery";
import EventNews from "./user/EventNews";
import ErrorPage from "./ErrorPage";
import { Helmet } from "react-helmet";
// import GalleryDelete from './admin/GalleryDelete'
// import Signup from './admin/Signup'
function App() {
  return (
    <Router>
      <Helmet>
        <title>Best Acting Institute in Ahmedabad | Best Acting Schools</title>
        <meta
          name="description"
          content="Learn acting at the best acting institute in ahmedabad . Visit our site and know more about acting classes and courses. We are the best acting school in ahmedabad."
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<ErrorPage />} />
        {/* <Route path="About" element={<About />} /> */}
        <Route path="WhyWeAreNo1" element={<WhyWeAreNo1 />} />
        <Route
          path="AboutNetrixEntertainment"
          element={<AboutNetrixEntertainment />}
        />
        <Route path="KKInstitute" element={<KKInstitute />} />
        <Route path="Footer" element={<Footer />} />
        <Route path="zumba-classes-zumba-dance-classes" element={<Zumba />} />
        <Route path="yoga-classes" element={<Yoga />} />
        <Route path="best-dance-classes" element={<Dance />} />
        <Route path="singing-classes-music-classes" element={<Singing />} />
        <Route path="best-modelling-agency" element={<Modelling />} />
        <Route
          path="grooming-and-personal-dvelopment-classes"
          element={<Grooming />}
        />
        <Route path="best-acting-classes" element={<Acting />} />
        <Route path="best-martial-arts-classes" element={<MixMartialArts />} />
        <Route
          path="best-acting-school-for-kids-and-children"
          element={<ChildActing />}
        />
        <Route path="WhatYouCanLearn" element={<WhatYouCanLearn />} />
        <Route path="professional-photoshoot" element={<Photoshoot />} />
        <Route
          path="diploma-in-acting-complete-acting-course"
          element={<CompleteActingCourse />}
        />
        <Route path="film-acting-school" element={<FilmActingCourse />} />
        <Route path="Contact" element={<Contact />} />
        {/* <Route path="AdminDashboard" element={<AdminDashboard />}/> */}
        <Route path="Signin" element={<Signin />} />
        {/* <Route path="Signup" element={<Signup />}/> */}
        <Route path="Pricing" element={<Pricing />} />
        <Route path="Banner" element={<Banner />} />
        <Route path="Images" element={<Images />} />
        {/* <Route path="Images2" element={<Images2 />}/> */}
        <Route path="FormData" element={<FormData />} />
        <Route path="Gallery" element={<Gallery />} />
        <Route path="EventNews" element={<EventNews />} />
        {/* <Route path="GalleryDelete" element={<GalleryDelete />}/> */}
      </Routes>
    </Router>
  );
}

export default App;
