import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { db } from "../Firebase";
import Footer from "../Footer";
import Nav from "../Nav";
import { motion } from "framer-motion";
import { FaCheck } from "react-icons/fa";

import { FiArrowRight } from "react-icons/fi";
import { Helmet } from "react-helmet";

function Acting() {
  const [actingPackages, setActingPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [actingkidsPackages, setActingkidsPackages] = useState([]);
  const [kidsloading, setkidsLoading] = useState(true);
  const [kidserror, setkidsError] = useState(null);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    course: "select course",
  });
  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Form Data"), formData);
      // Success! Data is saved to Firestore.
      toast.log("Form data saved");
      // Reset the form
      setFormData({
        name: "",
        email: "",
        number: "",
        course: "select course",
      });
    } catch (error) {
      // Handle error
      toast("Error saving form data: ", error);
    }
  };
  useEffect(() => {
    const fetchActingPackages = async () => {
      try {
        const packagesRef = collection(db, "packages");
        const q = query(packagesRef, where("course", "==", "Acting"));
        const querySnapshot = await getDocs(q);

        const actingPackageData = querySnapshot.docs.map((doc) => doc.data());
        const sortedActingPackages = actingPackageData.sort(
          (a, b) => a.amount - b.amount
        );
        setActingPackages(sortedActingPackages);
        setLoading(false);
      } catch (error) {
        toast("Error fetching acting packages:", error);
        setError("Error fetching acting packages");
        setLoading(false);
      }
    };

    fetchActingPackages();
    const fetchKidsActingPackages = async () => {
      try {
        const packageskidsRef = collection(db, "packages");
        const kidsq = query(
          packageskidsRef,
          where("course", "==", "Kids Acting")
        );
        const querykidsSnapshot = await getDocs(kidsq);

        const actingkidsPackageData = querykidsSnapshot.docs.map((doc) =>
          doc.data()
        );
        const sortedkidsActingPackages = actingkidsPackageData.sort(
          (a, b) => a.amount - b.amount
        );
        setActingkidsPackages(sortedkidsActingPackages);
        setkidsLoading(false);
      } catch (error) {
        toast("Error fetching acting packages:", kidserror);
        setkidsError("Error fetching acting packages");
        setkidsLoading(false);
      }
    };

    fetchKidsActingPackages();

    const fetchImages = async () => {
      const storage = getStorage();
      const coursesRef = ref(storage, "courses");
      const actingRef = ref(coursesRef, "Acting");

      try {
        const imageList = await listAll(actingRef);
        const imageURLs = await Promise.all(
          imageList.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            // console.log(downloadURL);
            return downloadURL;
          })
        );
        setImages(imageURLs);
        // console.log(imageURLs);
      } catch (error) {
        toast("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const peopleList = [
    { id: 1, group: "People with little or no acting experience." },
    {
      id: 2,
      group:
        "People who have some theater experience but little or no film experience.",
    },
    {
      id: 3,
      group:
        "An eclectic group of people with diverse backgrounds from all over the world.",
    },
    { id: 4, group: "Colleges & schools dramatics & theatre teams." },
  ];

  const goals = [
    "Observation & Imagination",
    "Accent & Diction",
    "Script Analysis, Characterization & Body Language Skills",
    "To understand the technicalities of short division, angles and magnification",
    "Understanding Technicalities of Camera & Lighting",
    "Emotions & Expressions",
    "Safety protocols while shooting",
    "Techniques of Transition of Emotions, Sense of Rhythm & Audition Preparation",
    "Identifying Strong & Weak Points",
    "Preparation for and Enactment of Scenes",
    "Dubbing, Recording & Voice Modulation",
    "Live studio visits for observation of professional actor performing their crafts",
    "Demo Film",
    "Participation in Real Shoot to Gain Practical Experience",
  ];
  return (
    <div className="bg-white">
      <Nav />
      <Helmet>
        <meta
          name="description"
          content="The KK Institute is the best acting classes in ahmedabad.Top-notch training, experienced instructors and a bright future in the limelight"
        />
      </Helmet>
      <section className="relative h-screen overflow-hidden">
        {images.map((imageUrl, index) => (
          <motion.img
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={imageUrl}
            alt={`Image ${index}`}
          />
        ))}

        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div className="container mx-auto h-full flex items-center justify-center">
          <motion.div
            initial={{ opacity: 1, y: -20 }}
            animate={{ opacity: 1, y: 20 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="text-center text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              Bringing Characters to Life with Passion and Authenticity
            </h1>
            <p className="text-lg md:text-2xl mb-6">
              Unleashing Emotions, Telling Stories, Captivating Hearts
            </p>
          </motion.div>
        </div>
      </section>
      <h2 className="my-5 text-center text-5xl font-bold">
        <span className="text-red-500">Best</span> Acting Classes In Ahmedabad
      </h2>
      <section className="bg-white ">
        <div className="container mx-auto flex flex-wrap items-center">
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-2/3 mb-8 md:mb-0"
          >
            <div className="px-8">
              <h2 className="text-4xl font-bold mb-4">
                Stepping into the Spotlight: The Art of Acting
              </h2>
              <p className="text-[#181823] mb-8">
                THE KK INSTITUTE is the best acting classes in ahmedabad. THE KK
                INSTITUTE curriculum provides the actors with effective tools
                and techniques that enable them to grow in the craft. THE KK
                INSTITUTE acting academy aims at offering all our students a
                broad and balanced curriculum that provides rewarding and
                stimulating activities to prepare them for the best social and
                cultural life. They are taught to deliver performances
                realistically using their vast imagination. They learn to
                express themselves in a convincing manner using a variety of
                impactful and powerful emotions. Through a perfect blend of
                theory, practicals,exercises, scene work, performances and
                discussions, aspiring actors are moulded into creative
                professionals capable of performing across platforms in their
                own unique inimitable style. The institute also regularly
                invites professionals from the media and entertainment industry
                to interact with our students. THE KK INSTITUTE is honoured to
                have had many Indian celebrities visit the institute and
                interact with our actors. THE KK INSTITUTE’s alumni have
                featured in many advertising commercials, plays, films,
                television shows and web series.
              </p>
              <p className="text-[#181823] mb-8">
                THE KK INSTITUTE is the no.1 acting classes in ahmedabad offers
                supportive and inspirational environments for young enquiring
                minds to learn and grow with us. We strive to build confident
                and creative thinkers and aim at delivering an education that is
                truly relevant to their future.
              </p>
              <p className="text-[#181823] mb-8">
                Our students walk out with the character and confidence to make
                their mark in the world, equipped with the knowledge and
                real-world skills that take them way ahead in the industry they
                may serve.
              </p>
              <p className="text-[#181823] mb-8">
                THE KK INSTITUTE is the leading acting classes in ahmedabad. We
                have an open admission policy which allows anyone to enter our
                programs regardless of previous experience. Our teachers
                specialise in bringing our diverse and eclectic groups of
                students to the peak of their abilities no matter what level
                they are at when they enter.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3 px-8"
          >
            <form onSubmit={handleSubmit}>
              {/* <!-- component --> */}
              <div className="min-h-screen  py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                  <div className="absolute inset-0 bg-gradient-to-r from-red-300 to-red-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                  <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                      <div>
                        <h2 className="text-2xl font-semibold">Enroll Now</h2>
                      </div>
                      <div className="divide-y divide-gray-200">
                        <div className="py-8 text-base leading-6 space-y-4 text-black-700 sm:text-lg sm:leading-7">
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="name"
                            />
                            <label
                              htmlFor="name"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-black peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Name
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Email address"
                            />
                            <label
                              htmlFor="email"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-black peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Email Address
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="number"
                              name="number"
                              type="number"
                              value={formData.number}
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Password"
                            />
                            <label
                              htmlFor="number"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-black peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Phone
                            </label>
                          </div>
                          <div className="relative">
                            <select
                              value={formData.course}
                              name="course"
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-600 focus:outline-none focus:borer-rose-600"
                            >
                              <option>Select Course</option>
                              <option>Complete Acting Course</option>
                              <option>Acting</option>
                              <option>Film Acting Course</option>
                              <option>Child Acting</option>
                              <option>Mix Martial Arts</option>
                              <option>Photoshoot</option>
                              <option>Dancing</option>
                              <option>Modelling</option>
                              <option>Grooming</option>
                              <option>Singing</option>
                              <option>Yoga</option>
                              <option>Zumba</option>
                            </select>
                            {/* <label htmlFor="number" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-black peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">phone</label> */}
                          </div>

                          <div className="relative">
                            <button className="bg-red-500 text-white rounded-md px-2 py-1">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </section>

      <div className="flex flex-col items-center justify-center h-60 bg-white">
        <h3 className="text-3xl font-bold   hover:scale-110 transition duration-300 hover:text-red-700">
          Many of our acting students are
        </h3>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {peopleList.map((person) => (
            <motion.li
              key={person.id}
              className="bg- p-4 rounded-md hover:text-red-700"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 * person.id }}
            >
              <div className="flex items-center">
                <FiArrowRight className="w-4 h-4 mr-2 text-red-500" />
                {person.group}
              </div>
            </motion.li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col items-center justify-center h-60  bg-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-10 h-10 text-red-700 mr-4"
          viewBox="0 0 24 24"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" stroke="currentColor" fill="none" />
          <path d="M8 12l2 2 4-4" stroke="currentColor" fill="none" />
        </svg>

        <div className="text-center">
          <h3 className="text-3xl font-bold mb-4 hover:scale-110 transition duration-300 hover:text-red-700">
            Our associations can help you reach your goals
          </h3>
          <p className="text-lg hover:text-red-700">
            With our connections to hundreds of big and small production houses
            across the country, you can achieve your goals quickly, provided you
            work equally hard with us.
          </p>
        </div>
      </div>

      <div className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4">
          <h3 className="text-3xl font-bold mb-8 text-center hover:scale-110 transition duration-300 hover:text-red-700">
            Acting Goals
          </h3>
          <ul className="list-none">
            {goals.map((goal, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                className="hover:text-red-700"
              >
                <svg
                  className="inline-block w-6 h-6 mr-2 text-red-700"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <FaCheck />
                </svg>
                <h2 className="text-xl font-semibold inline-block">{goal}</h2>
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
      <div className="h-full mb-20 flex flex-col justify-center items-center px-4 lg:px-0">
        <div className="w-full max-w-3xl mx-auto">
          <div className="text-center font-semibold">
            <h3 className="text-3xl md:text-5xl">
              <span className="text-red-700 tracking-wide">Acting </span>
              <span>packages</span>
            </h3>
            <p className="pt-4 md:pt-6 text-base md:text-xl text-gray-400 font-normal">
              Choose a plan that works best for you
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between pt-8 md:pt-24">
            {actingPackages.map((p) => (
              <div
                key={p.packageType}
                className={`w-full md:w-1/3 p-4 md:p-8 even:bg-black even:text-white text-center rounded-3xl shadow-xl`}
              >
                <h3 className="text-lg md:text-2xl font-semibold">
                  {p.packageType}
                </h3>
                <p className="pt-2 tracking-wide">
                  <span className="text-gray-400 align-top">₹ </span>
                  <span className="text-xl md:text-3xl font-semibold">
                    {p.amount}
                  </span>
                </p>
                <hr className="mt-4 border-1" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="h-full mb-20 flex flex-col justify-center items-center px-4 lg:px-0">
        <div className="w-full max-w-3xl mx-auto">
          <div className="text-center font-semibold">
            <h3 className="text-3xl md:text-5xl">
              <span className="text-red-700 tracking-wide">Kids Acting </span>
              <span>packages</span>
            </h3>
            <p className="pt-4 md:pt-6 text-base md:text-xl text-gray-400 font-normal">
              Choose a plan that works best for you
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between pt-8 md:pt-24">
            {actingkidsPackages.map((p) => (
              <div
                key={p.packageType}
                className={`w-full md:w-1/3 p-4 md:p-8 even:bg-black even:text-white text-center rounded-3xl shadow-xl`}
              >
                <h3 className="text-lg md:text-2xl font-semibold">
                  {p.packageType}
                </h3>
                <p className="pt-2 tracking-wide">
                  <span className="text-gray-400 align-top">₹ </span>
                  <span className="text-xl md:text-3xl font-semibold">
                    {p.amount}
                  </span>
                </p>
                <hr className="mt-4 border-1" />
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
      <ToastContainer />
    </div>
  );
}

export default Acting;
