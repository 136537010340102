import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import welcome from '../src/user/images/welcome.gif'

import { getStorage, ref, getDownloadURL,listAll } from "firebase/storage";
import { storage } from "./Firebase";


const SliderComponent = () => {
  const [images, setImages] = useState([]);
  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    arrows: false,
  };
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const storageRef = ref(storage, "images");
        const imageRefs = await listAll(storageRef);

        const imageURLs = await Promise.all(
          imageRefs.items.map(async (item) => {
            const downloadURL = await getDownloadURL(item);
            return downloadURL;
          })
        );

        setImages(imageURLs);
      } catch (error) {
        console.log("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);
  return (
    <div className="h-full bg-black text-white relative">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slide bg-transparent h-3/5 flex items-center justify-center">
          <img src={image} alt="Slide" className="h-[300px] w-full sm:h-[600px]"  />
        </div>
        ))}
      </Slider>
      <img className="w-full h-[300px] sm:h-[600px]" src={welcome} alt="Welcome" />
    </div>
  );
};
export default SliderComponent;
