import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../Footer";
import Nav from "../Nav";
import no from "./images/no.png";
function WhyWeAreNo1() {
  return (
    <div>
      <Nav />
      <Helmet>
        <title>No.1 Acting School in Ahmedabad</title>
        <meta
          name="description"
          content="We are #1 Acting School for comprehensive acting training and industry exposure"
        />
      </Helmet>
      <section className="text-gray-600 body-font">
        <div className="container px-5  mx-auto flex flex-wrap">
          <div className="lg:w-1/2 w-full mt-10 mb-10 lg:mb-0 rounded-lg overflow-hidden">
            <img
              alt="feature"
              className="object-cover object-center h-full w-full"
              src={no}
            />
          </div>
          <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center items-center justify-center">
            <section className=" dark:text-black">
              <div className="container max-w-5xl px-4 py-12 mx-auto">
                <div className="grid gap-4 mx-4 sm:grid-cols-12">
                  <div className="relative col-span-12 px-4 space-y-6 sm:col-span-9">
                    <div className="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:dark:bg-gray-700">
                      <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-red-700">
                        <h3 className="text-xl font-semibold tracking-wide">
                          Have own production house{" "}
                        </h3>

                        <p className="mt-3">
                          We have our own production house for performing arts
                          and films.
                        </p>
                      </div>
                      <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-red-700">
                        <h3 className="text-xl font-semibold tracking-wide">
                          Have produced hindi movies
                        </h3>

                        <p className="mt-3">
                          Hindi movies often revolve around themes such as love,
                          family, and friendship, and usually, the storyline.
                        </p>
                      </div>
                      <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-red-700">
                        <h3 className="text-xl font-semibold tracking-wide">
                          Have own music channels
                        </h3>

                        <p className="mt-3">
                          Having own music channel can be an exciting
                          opportunity to showcase your love for music.
                        </p>
                      </div>
                      <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-red-700">
                        <h3 className="text-xl font-semibold tracking-wide">
                          Have own Entertainment channels
                        </h3>

                        <p className="mt-3">
                          Having our own entertainment channel can be a great
                          way to showcase your creativity and share your passion
                          for entertainment with others.{" "}
                        </p>
                      </div>
                      <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-red-700">
                        <h3 className="text-xl font-semibold tracking-wide">
                          Producing Gujarati movies in 2023
                        </h3>

                        <p className="mt-3">
                          Producing Gujarati movies in 2023 presents a promising
                          opportunity for filmmakers to explore the unique
                          cultural heritage of Gujarat and showcase it to a
                          wider audience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default WhyWeAreNo1;
