import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "../Firebase";
import Footer from "../Footer";
import Nav from "../Nav";
import { motion } from "framer-motion";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { Helmet } from "react-helmet";
function Yoga() {
  const [images, setImages] = useState([]);
  const benefitsList = [
    "Body awareness",
    "Increased stamina",
    "Physical flexibility and strength",
    "Emotional balance",
    "Stress reduction",
    "Proprioceptive sensitivity",
    "Deeper focus",
    "Calms nerves and anxiety",
    "Increased creativity",
    "Confidence",
    "Endurance and resilience (physical and mental)",
    "Overall well-being",
  ];

  const [actingPackages, setActingPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    course: "select course",
  });
  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Form Data"), formData);
      // Success! Data is saved to Firestore.
      toast("Form data saved");
      // Reset the form
      setFormData({
        name: "",
        email: "",
        number: "",
        course: "select course",
      });
    } catch (error) {
      // Handle error
      toast("Error saving form data: ", error);
    }
  };
  useEffect(() => {
    const fetchActingPackages = async () => {
      try {
        const packagesRef = collection(db, "packages");
        const q = query(packagesRef, where("course", "==", "Yoga"));
        const querySnapshot = await getDocs(q);

        const actingPackageData = querySnapshot.docs.map((doc) => doc.data());
        const sortedActingPackages = actingPackageData.sort(
          (a, b) => a.amount - b.amount
        );
        setActingPackages(sortedActingPackages);
        setLoading(false);
      } catch (error) {
        toast("Error fetching  packages:", error);
        setError("Error fetching  packages");
        setLoading(false);
      }
    };

    fetchActingPackages();
    const fetchImages = async () => {
      const storage = getStorage();
      const coursesRef = ref(storage, "courses");
      const actingRef = ref(coursesRef, "Yoga");

      try {
        const imageList = await listAll(actingRef);
        const imageURLs = await Promise.all(
          imageList.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            // console.log(downloadURL);
            return downloadURL;
          })
        );
        setImages(imageURLs);
        // console.log(imageURLs);
      } catch (error) {
        toast("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div>
      <Nav />
      <Helmet>
        <title>Yoga Classes in Ahmedabad | Best Yoga Studio</title>
        <meta
          name="description"
          content="The KK Institute in Ahmedabad offers expert yoga classes for holistic wellness and inner peace."
        />
      </Helmet>
      <section className="relative h-screen overflow-hidden">
        {images.map((imageUrl, index) => (
          <motion.img
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={imageUrl}
            alt={`Image ${index}`}
          />
        ))}
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div className="container mx-auto h-full flex items-center justify-center">
          <motion.div
            initial={{ opacity: 1, y: -20 }}
            animate={{ opacity: 1, y: 20 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="text-center text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              Welcome to Yoga!
            </h1>
            <p className="text-lg md:text-2xl mb-6">
              Unleash the power of your mind, body and soul
            </p>
          </motion.div>
        </div>
      </section>
      <h2 className="my-5 text-center text-5xl font-bold">
        <span className="text-red-500">Yoga </span>
        Classes in Ahmedabad
      </h2>
      <section className="bg-white py-12">
        <div className="container mx-auto flex flex-wrap items-center">
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-2/3 mb-8 md:mb-0"
          >
            <div className="px-8">
              <h2 className="text-4xl font-bold mb-4">
                Why integrate yoga into acting class?
              </h2>
              <p className="text-gray-600 mb-8">
                Incorporating yoga into the curriculum is an extremely powerful
                tool that further aids you in getting you out of your head and
                into your body, into your heart so you may live truthfully on
                stage, on camera and in life.
              </p>
              <p className="text-gray-600 mb-8">
                Yoga and acting share the common demand of complete presence in
                the moment. Incorporating yoga practices into acting lessons
                fosters relaxation and cultivates the necessary centeredness for
                effectively tapping into your instincts. THE KK INSTITUTE is the
                best yoga studio providing yoga technique is paving the way for
                the modern day actor. Our conviction lies in the belief that
                acting is a spiritual vocation. This belief stems from the
                profound potential of a compelling theatrical, television or
                cinematic production to forge connections with others and
                illuminate one's own humanity. Such experiences have the power
                to bring about remarkable transformations in one's life.
              </p>
              <p className="text-gray-600 mb-8">
                THE KK INSTITUTE is one of the premier yoga classes in ahmedabad
                that guides you to enhance your inner beauty and personality
                through yoga practices.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3 px-8"
          >
            <form onSubmit={handleSubmit}>
              {/* <!-- component --> */}
              <div className="min-h-screen  py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                  <div className="absolute inset-0 bg-gradient-to-r from-red-300 to-red-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                  <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                      <div>
                        <h2 className="text-2xl font-semibold">Enroll Now</h2>
                      </div>
                      <div className="divide-y divide-gray-200">
                        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="name"
                            />
                            <label
                              htmlFor="name"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Name
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Email address"
                            />
                            <label
                              htmlFor="email"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Email Address
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="number"
                              name="number"
                              type="number"
                              value={formData.number}
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Password"
                            />
                            <label
                              htmlFor="number"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Phone
                            </label>
                          </div>
                          <div className="relative">
                            <select
                              value={formData.course}
                              name="course"
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                            >
                              <option>Select Course</option>
                              <option>Complete Acting Course</option>
                              <option>Acting</option>
                              <option>Film Acting Course</option>
                              <option>Child Acting</option>
                              <option>Mix Martial Arts</option>
                              <option>Photoshoot</option>
                              <option>Dancing</option>
                              <option>Modelling</option>
                              <option>Grooming</option>
                              <option>Singing</option>
                              <option>Yoga</option>
                              <option>Zumba</option>
                            </select>
                            {/* <label htmlFor="number" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">phone</label> */}
                          </div>

                          <div className="relative">
                            <button className="bg-red-500 text-white rounded-md px-2 py-1">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </section>
      <div>
        {/* Container */}
        <div className="bg-white py-10">
          <div className="container mx-auto px-4">
            {/* Benefits Section */}
            <div className="bg-white rounded-lg shadow-md p-8 text-center">
              <h2 className="text-3xl font-bold mb-10">
                What Yoga Can Do for Actors?
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                  className="bg-white rounded-lg p-6 shadow-md text-center"
                >
                  <h3 className="text-2xl font-semibold mb-4">
                    Physical Fitness
                  </h3>
                  <p className="text-lg">
                    Yoga can aid actors in enhancing their physical fitness by
                    augmenting strength, flexibility and stamina—essential
                    attributes for tackling demanding roles and delivering
                    exceptional performances.
                  </p>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.4 }}
                  className="bg-white rounded-lg p-6 shadow-md text-center"
                >
                  <h3 className="text-2xl font-semibold mb-4">Stress Relief</h3>
                  <p className="text-lg">
                    Yoga classes serves as a potent instrument in effectively
                    coping with stress and anxiety, enabling actors to maintain
                    a state of concentration, serenity and mindfulness, whether
                    they are performing on stage or working behind the scenes.
                  </p>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.6 }}
                  className="bg-white rounded-lg p-6 shadow-md text-center"
                >
                  <h3 className="text-2xl font-semibold mb-4">
                    Emotional Well-being
                  </h3>
                  <p className="text-lg">
                    Yoga has the capacity to foster emotional well-being by
                    elevating self-awareness, self-acceptance and emotional
                    resilience, thereby assisting actors in establishing a
                    profound connection with their characters and delivering
                    genuine and compelling performances.
                  </p>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* Container */}
        <div className=" ">
          <div className="container  mx-auto ">
            {/* Benefits Section */}
            <div className="bg-black rounded-lg shadow-md p-8 text-center">
              <h2 className="text-white text-3xl font-bold mb-10">
                12 Ways Yoga Can Help Your Acting Career
              </h2>
              <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {benefitsList.map((benefit, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: index * 0.1 }}
                    className="bg-red-500 rounded-md p-4 shadow-md text-center"
                  >
                    <p className="text-xl font-bold text-white">{benefit}</p>
                  </motion.li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 h-full mb-20 flex flex-col justify-center items-center px-4 lg:px-0">
        <div className="w-full max-w-3xl mx-auto">
          <div className="text-center font-semibold">
            <h2 className="text-3xl md:text-5xl">
              <span className="text-red-700 tracking-wide">Yoga </span>
              <span>packages</span>
            </h2>
            <p className="pt-4 md:pt-6 text-base md:text-xl text-gray-400 font-normal">
              Choose a plan that works best for you
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between pt-8 md:pt-24">
            {actingPackages.map((p) => (
              <div
                key={p.packageType}
                className={`w-full md:w-1/3 p-4 md:p-8 even:bg-black even:text-white text-center rounded-3xl shadow-xl`}
              >
                <h2 className="text-lg md:text-2xl font-semibold">
                  {p.packageType}
                </h2>
                <p className="pt-2 tracking-wide">
                  <span className="text-gray-400 align-top">₹ </span>
                  <span className="text-xl md:text-3xl font-semibold">
                    {p.amount}
                  </span>
                </p>
                <hr className="mt-4 border-1" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default Yoga;
