import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { db } from "../Firebase";
import Signin from "./Signin";
import pricingadmin from "../user/images/pricingadmin.png";
import logoutadmin from "../user/images/logoutadmin.png";
import coursesadmin from "../user/images/coursesadmin.png";
import imageadmin from "../user/images/imageadmin.png";
import banneradmin from "../user/images/banneradmin.png";
import dashboardadmin from "../user/images/dashboardadmin.png";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { app } from "../Firebase";
const auth = getAuth(app);
function FormData() {
  const [images, setImages] = useState([]);
  const [formDataList, setFormDataList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [progress, setProgress] = useState(0);

  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // yes you are logged in
        setUser(user);
      } else {
        // user is logged out
        setUser(null);
      }
    });
    const fetchData = async () => {
      const formDataCollection = collection(db, "Form Data");
      const snapshot = await getDocs(formDataCollection);
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFormDataList(data);
    };

    fetchData();
  }, []);
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "Form Data", id));
      setFormDataList((prevFormDataList) =>
        prevFormDataList.filter((formData) => formData.id !== id)
      );
      
      toast('Form data deleted');
    } catch (error) {
      toast("Error deleting form data ",error)
    }
  };

  // Rest of the code remains the same

  if (user === null) {
    return (
      <div>
        <Signin />
      </div>
    );
  }
  return (
    <div>
      <div class="min-h-screen w-full flex overflow-hidden select-none">
        <nav class="w-16 sm:w-24 flex flex-col items-center bg-white dark:bg-white py-4">
          <div>
            <img class="h-6 sm:h-8 w-6 sm:w-8 fill-current text-red-600 dark:text-red-300" />
          </div>

          <ul class="mt-2  text-red-600 dark:text-red-300   capitalize">
            <li class="mt-3 p-2  rounded-lg">
              <a href="/FormData" class="flex flex-col items-center">
                <img src={dashboardadmin} class="h-5 w-5" alt="Dashboard" />
                <span class="text-xs mt-2">Form Data</span>
              </a>
            </li>

            <li class="mt-3 p-2  hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Banner" class="flex flex-col items-center">
                <img src={banneradmin} class="h-5 w-5" alt="Banner" />
                <span class="text-xs  text-black  mt-2">Banner</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Images" class="flex flex-col items-center">
                <img src={imageadmin} class="h-5 w-5" alt="Image" />
                <span class="text-xs text-black mt-2">Image</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Gallery" class="flex flex-col items-center">
                <img src={coursesadmin} class="h-5 w-5" alt="Courses" />
                <span class="text-xs text-black mt-2">Gallery</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600  rounded-lg">
              <a href="/Pricing" class="flex flex-col items-center">
                <img src={pricingadmin} class="h-5 w-5" alt="Pricing" />
                <span class="text-xs text-black  mt-2">Pricing</span>
              </a>
            </li>
          </ul>

          <div class="mt-auto flex items-center p-2 text-red-700 bg-gray-200 dark:text-red-500 rounded-full">
            <button onClick={() => signOut(auth)}>
              <img src={logoutadmin} class="h-5 w-5" alt="Logout" />
            </button>
          </div>
        </nav>

        <main className="my-1 pt-2 pb-2 px-2 sm:px-10 flex-1 bg-gray-200 rounded-l-lg transition duration-500 ease-in-out overflow-y-auto">
          <h1 className="my-5 text-center text-3xl font-bold">
            <span className="text-red-500">Form</span> Data
          </h1>
          <div className="grid grid-cols-1 gap-4">
            {formDataList.map((formData, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg p-4">
                {/* Form data details */}
                <div className="grid grid-cols-2 gap-4">
                  <p>
                    <span className="font-semibold">Name:</span> {formData.name}
                  </p>
                  <p>
                    <span className="font-semibold">Email:</span>{" "}
                    {formData.email}
                  </p>
                  <p>
                    <span className="font-semibold">Phone:</span>{" "}
                    {formData.number}
                  </p>
                  <p>
                    <span className="font-semibold">Course:</span>{" "}
                    {formData.course}
                  </p>
                </div>
                {/* Delete button */}
                <button
                  className="bg-red-500 text-white rounded-md px-2 py-1 mt-4"
                  onClick={() => handleDelete(formData.id)}
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
}

export default FormData;
