import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../Footer";
import Nav from "../Nav";

function AboutNetrixEntertainment() {
  return (
    <div>
      <Nav />
      <Helmet>
        <title>About Netrix Entertanment</title>
        <meta
          name="description"
          content="NETRIX ENTERTAINMENT PVT LTD is a Mumbai-based production house established in 2017. The KK Institute is a part of this production house."
        />
      </Helmet>
      <section className=" body-font">
        <h1 className="my-5 text-center text-3xl font-bold">
          <span className="text-red-500">About</span> NETRIX ENTERTAINMENT PVT
          LTD
        </h1>
        <div className="container px-5 pb-10 mx-auto">
          <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-500 sm:flex-row flex-col">
            <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-red-100 text-red-700 flex-shrink-0">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="sm:w-16 sm:h-16 w-10 h-10"
                viewBox="0 0 24 24"
              >
                <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
              </svg>
            </div>
            <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
              {/* <h2 className="text-gray-900 text-lg title-font font-medium mb-2">Shooting Stars</h2> */}
              <p className="leading-relaxed text-base">
                {" "}
                <span className="font-black">
                  {" "}
                  NETRIX ENTERTAINMENT PVT LTD{" "}
                </span>
                is a Mumbai-based production house established in 2017. THE KK
                INSTITUTE OF ACTING is a part of this production house and the
                aim of this production house is to give the best possible
                opportunity to our students.
              </p>
            </div>
          </div>
          <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-500 sm:flex-row flex-col">
            <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
              {/* <h2 className="text-gray-900 text-lg title-font font-medium mb-2">The Catalyzer</h2> */}
              <p className="leading-relaxed text-base">
                <span className="font-black">
                  {" "}
                  NETRIX ENTERTAINMENT PVT LTD{" "}
                </span>{" "}
                has worked with the famous film and TV celebs and singers like
                Anupam Kher, Isha Gupta, Rubina Dilaik, Sharad Malhotra, Paras
                Arora, Sourabh Raj Jain, Ruslaan Mumtaz, Jubin Nautiyal, Javed
                Ali, Palak Muchhal & Jyotica Tangri
              </p>
            </div>
            <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-red-100 text-red-500 flex-shrink-0">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="sm:w-16 sm:h-16 w-10 h-10"
                viewBox="0 0 24 24"
              >
                <circle cx="6" cy="6" r="3"></circle>
                <circle cx="6" cy="18" r="3"></circle>
                <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
              </svg>
            </div>
          </div>
          <div className="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col">
            <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-red-100 text-red-500 flex-shrink-0">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="sm:w-16 sm:h-16 w-10 h-10"
                viewBox="0 0 24 24"
              >
                <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </div>
            <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
              {/* <h2 className="text-gray-900 text-lg title-font font-medium mb-2">The 400 Blows</h2> */}
              <p className="leading-relaxed text-base">
                <span className="font-black">
                  {" "}
                  NETRIX ENTERTAINMENT PVT LTD{" "}
                </span>{" "}
                has produced films like ONE DAY JUSTICE DELIVERED & DEAR DIA.
                This production house also runs a Music Label in the name of
                NETRIX MUSIC with the most prominent singers of the industry
                including Hindi & Punjabi songs. Company also owns Netrix
                Entertainment Youtube Channel which will help our students to
                show their talents.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default AboutNetrixEntertainment;
