
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyASPTG40Wg2oI3FNamTY6JmFVIVixSDk2I",
  authDomain: "the-kk-institute.firebaseapp.com",
  projectId: "the-kk-institute",
  storageBucket: "the-kk-institute.appspot.com",
  messagingSenderId: "119486026037",
  appId: "1:119486026037:web:48bffb9f9aa0aadb4ebc7c"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);