import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "../Firebase";
import Footer from "../Footer";
import Nav from "../Nav";
import { motion } from "framer-motion";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { Helmet } from "react-helmet";

function CompleteActingCourse() {
  const [images, setImages] = useState([]);
  const [actingPackages, setActingPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    course: "select course",
  });
  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Form Data"), formData);
      // Success! Data is saved to Firestore.
      toast("Form data saved ");
      // Reset the form
      setFormData({
        name: "",
        email: "",
        number: "",
        course: "select course",
      });
    } catch (error) {
      // Handle error
      toast("Error saving form data: ", error);
    }
  };
  useEffect(() => {
    const fetchActingPackages = async () => {
      try {
        const packagesRef = collection(db, "packages");
        const q = query(
          packagesRef,
          where("course", "==", "Complete Acting Course")
        );
        const querySnapshot = await getDocs(q);

        const actingPackageData = querySnapshot.docs.map((doc) => doc.data());
        const sortedActingPackages = actingPackageData.sort(
          (a, b) => a.amount - b.amount
        );
        setActingPackages(sortedActingPackages);
        setLoading(false);
      } catch (error) {
        toast("Error fetching  packages:", error);
        setError("Error fetching complete  packages");
        setLoading(false);
      }
    };

    fetchActingPackages();

    const fetchImages = async () => {
      const storage = getStorage();
      const coursesRef = ref(storage, "courses");
      const actingRef = ref(coursesRef, "Complete Acting Course");

      try {
        const imageList = await listAll(actingRef);
        const imageURLs = await Promise.all(
          imageList.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);
            // console.log(downloadURL);
            return downloadURL;
          })
        );
        setImages(imageURLs);
        // console.log(imageURLs);
      } catch (error) {
        toast("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const testimonialData = [
    {
      quote: "Acting",
      //   author: "Jane Doe, Actress"
    },
    {
      quote: "Camera Facing",
      //   author: "John Smith, Actor"
    },
    {
      quote: "Navras (Nine Emotions)",
      //   author: "Sarah Johnson, Actress"
    },
    {
      quote: "Accent",
      //   author: "Michael Brown, Actor"
    },
    {
      quote: "Diction",
      //   author: "Alex Thompson, Actress"
    },
    {
      quote: "Body Language",
      //   author: "David Lee, Actor"
    },
    {
      quote: "Personality Development",
      //   author: "Olivia Chen, Actress"
    },
    {
      quote: "Dialogue Delivery",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "Voice Modulation",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "Complete Knowledge About Acting Industry",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "Basic part of Camera Knowledge",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "Basic part of Direction",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "Basic part of Script Writing skills",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "Basic part of Fitness",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "Yoga",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "Dance",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "Zumba",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "Instagram Bluetick",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "Portfolio Shoot",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "References of casting Director in Mumbai",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote:
        "Contact number & Venue details of active production houses in Mumbai.",
      //   author: "Daniel Kim, Actor"
    },
    {
      quote: "Certificate of Completion",
      //   author: "Daniel Kim, Actor"
    },
  ];

  const packages = [
    { title: "", price: "5 Lakhs" },
    // { title: "Half Yearly", price: "25,000" },
    // { title: "Yearly", price: "40,000" },
  ];

  return (
    <div>
      <Nav />
      <Helmet>
        <title>Diploma in Acting in Ahmadabad | Complete Acting Course</title>
        <meta
          name="description"
          content="At The KK Institute we provide diploma in acting this 
          complet acting course provide knowledge about acting industry"
        />
      </Helmet>
      <section className="relative h-screen overflow-hidden">
        {images.map((imageUrl, index) => (
          <motion.img
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={imageUrl}
            alt={`Image ${index}`}
          />
        ))}
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div className="container mx-auto h-full flex items-center justify-center">
          <motion.div
            initial={{ opacity: 1, y: -20 }}
            animate={{ opacity: 1, y: 20 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="text-center text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              Master the art of acting with our complete course.
            </h1>
            <p className="text-lg md:text-2xl mb-6">
              Unlock your potential as an actor with our comprehensive program.
            </p>
          </motion.div>
        </div>
      </section>
      <h2 className="my-5 text-center text-5xl font-bold">
        <span className="text-red-500">Master the Art of Acting</span> with our
        Complete Acting Course in Ahmedabad
      </h2>
      <section className="bg-white ">
        <div className="container mx-auto flex flex-wrap items-center">
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-2/3 mb-8 md:mb-0"
          >
            <div className="px-8">
              <h2 className="text-4xl font-bold mb-4">
                Complete Acting Course
              </h2>
              <p className="text-gray-600 mb-8">
                THE KK INSTITUTE complete acting course is an extensive program
                designed to provide aspiring actors with a comprehensive
                education in the art of acting. This complete acting course
                typically covers a wide range of topics, including fundamental
                acting techniques, character development, script analysis, voice
                training and physical movement. Students learn to harness their
                emotions and creativity to create authentic and compelling
                performances for various mediums, such as theatre, film and
                television. The curriculum often includes practical exercises,
                scene work, monologue and ensemble performances to help students
                apply their newfound skills. Additionally, students may explore
                the history of acting, the business of the entertainment
                industry and audition techniques. Through the entire acting
                course, participants should have a solid foundation in acting
                and be well-prepared to pursue a career in the performing arts
                After the completion of this diploma in acting course, an actor
                would be given a guaranteed opportunity to work in any of the
                content produced under NETRIX ENTERTAINMENT PRIVATE LIMITED
                banner.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3 px-8"
          >
            <form onSubmit={handleSubmit}>
              {/* <!-- component --> */}
              <div className="min-h-screen  py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                  <div className="absolute inset-0 bg-gradient-to-r from-red-300 to-red-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                  <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                      <div>
                        <h2 className="text-2xl font-semibold">Enroll Now</h2>
                      </div>
                      <div className="divide-y divide-gray-200">
                        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="name"
                            />
                            <label
                              htmlFor="name"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Name
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              type="text"
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Email address"
                            />
                            <label
                              htmlFor="email"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Email Address
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              autoComplete="off"
                              id="number"
                              name="number"
                              type="number"
                              value={formData.number}
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                              placeholder="Password"
                            />
                            <label
                              htmlFor="number"
                              className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                            >
                              Phone
                            </label>
                          </div>
                          <div className="relative">
                            <select
                              value={formData.course}
                              name="course"
                              onChange={handleChange}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-600 focus:outline-none focus:borer-rose-600"
                            >
                              <option>select course</option>
                              <option>Complete Acting Course</option>
                              <option>Acting</option>
                              <option>Film Acting Course</option>
                              <option>Child Acting</option>
                              <option>Mix Martial Arts</option>
                              <option>Photoshoot</option>
                              <option>Dancing</option>
                              <option>Modelling</option>
                              <option>Grooming</option>
                              <option>Singing</option>
                              <option>Yoga</option>
                              <option>Zumba</option>
                            </select>
                            {/* <label htmlFor="number" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">phone</label> */}
                          </div>

                          <div className="relative">
                            <button className="bg-red-500 text-white rounded-md px-2 py-1">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </section>
      <center>
        <h2 className="text-3xl font-bold mb-4">
          This course will cover the following aspects:
        </h2>
      </center>
      <div className="container mx-auto px-4">
        {/* Other content in your app */}

        <motion.ul
          className="flex flex-wrap justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {testimonialData.map((testimonial, index) => (
            <motion.li
              key={index}
              className="flex items-center bg-white shadow-lg rounded-md p-6 m-4 w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-red-700 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
              <p className="text-gray-600 text-lg">{testimonial.quote}</p>
            </motion.li>
          ))}
        </motion.ul>
      </div>

      <div className="mt-5 h-full mb-20 px-4 lg:px-0 mx-auto flex justify-center">
        <div className="w-full max-w-3xl">
          <div className="text-center font-semibold">
            <h2 className="text-3xl md:text-5xl">
              <span className="text-red-700 tracking-wide">
                Complete Acting{" "}
              </span>
              <span>packages</span>
            </h2>
            <p className="pt-4 md:pt-6 text-base md:text-xl text-gray-400 font-normal">
              Choose a plan that works best for you
            </p>
          </div>
          <div className="pt-8 md:pt-24 m-0 flex justify-center ">
            {actingPackages.map((p) => (
              <div
                key={p.packageType}
                className={`w-full md:w-1/3 p-4 md:p-8 even:bg-black even:text-white text-center rounded-3xl shadow-xl`}
              >
                <p className="pt-2 ">
                  <span className="text-gray-400 align-top">₹ </span>
                  <span className="text-xl md:text-3xl font-semibold">
                    {p.amount}
                  </span>
                </p>
                <hr className="mt-4 border-1" />
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
      <ToastContainer />
    </div>
  );
}

export default CompleteActingCourse;
