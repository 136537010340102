import React from "react";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc, query, where, getDocs, deleteDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { app } from "../Firebase";
import Signin from "./Signin";
import pricingadmin from "../user/images/pricingadmin.png";
import logoutadmin from "../user/images/logoutadmin.png";
import coursesadmin from "../user/images/coursesadmin.png";
import imageadmin from "../user/images/imageadmin.png";
import banneradmin from "../user/images/banneradmin.png";
import dashboardadmin from "../user/images/dashboardadmin.png";
const auth = getAuth(app);

function Pricing() {
  const [course, setCourse] = useState("");
  const [packageType, setPackageType] = useState("");
  const [amount, setAmount] = useState("");
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Check if the package already exists
    const packagesRef = collection(db, "packages");
    const duplicateQuery = query(packagesRef, where("course", "==", course), where("packageType", "==", packageType));
    const duplicateSnapshot = await getDocs(duplicateQuery);

    if (!duplicateSnapshot.empty) {
      // Delete the old package
      duplicateSnapshot.docs.forEach((doc) => {
        deleteDoc(doc.ref);
      });
    }

    // Create a new package object with the form data
    const newPackage = {
      course,
      packageType,
      amount: parseFloat(amount), // Convert amount to a number
    };

    try {
      // Add a new document to the "packages" collection in Firestore
      await addDoc(packagesRef, newPackage);

      toast("Package updated successfully!");
      // Reset the form fields and error message
      setCourse("");
      setPackageType("");
      setAmount("");
      setErrorMessage("");
    } catch (error) {
      console.error("Error updating package:", error);
    }
  };
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // yes you are logged in
        setUser(user);
      } else {
        // user is logged out
        setUser(null);
      }
    });
  }, []);
  if (user === null) {
    return (
      <div>
        <Signin />
      </div>
    );
  }
  return (
    <div>
      <div class="min-h-screen w-full flex overflow-hidden select-none">
        <nav class="w-16 sm:w-24 flex flex-col items-center bg-white dark:bg-white py-4">
          <div>
            <img class="h-6 sm:h-8 w-6 sm:w-8 fill-current text-red-600 dark:text-red-300" />
          </div>

          <ul class="mt-2 text-gray-700 dark:text-gray-400 capitalize">
            <li class="mt-3 p-2  rounded-lg">
              <a href="/FormData" class="flex flex-col items-center">
                <img src={dashboardadmin} class="h-5 w-5" alt="Dashboard" />
                <span class="text-xs text-black mt-2">Form Data</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Banner" class="flex flex-col items-center">
                <img src={banneradmin} class="h-5 w-5" alt="Banner" />
                <span class="text-xs text-black mt-2">Banner</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Images" class="flex flex-col items-center">
                <img src={imageadmin} class="h-5 w-5" alt="Image" />
                <span class="text-xs text-black mt-2">Image</span>
              </a>
            </li>

            <li class="mt-3 p-2 hover:text-red-600 dark-hover:text-red-300 rounded-lg">
              <a href="/Gallery" class="flex flex-col items-center">
                <img src={coursesadmin} class="h-5 w-5" alt="Courses" />
                <span class="text-xs text-black mt-2">Gallery</span>
              </a>
            </li>

            <li class="mt-3 p-2 text-red-600 dark:text-red-300 hover:text-red-600  rounded-lg">
              <a href="/Pricing" class="flex flex-col items-center">
                <img src={pricingadmin} class="h-5 w-5" alt="Pricing" />
                <span class="text-xs  mt-2">Pricing</span>
              </a>
            </li>
          </ul>

          <div class="mt-auto flex items-center p-2 text-red-700 bg-gray-200 dark:text-red-500 rounded-full">
            <button onClick={() => signOut(auth)}>
              <img src={logoutadmin} class="h-5 w-5" alt="Logout" />
            </button>
          </div>
        </nav>

        <main class="my-1 pt-2 pb-2 px-2 sm:px-10 flex-1 bg-gray-200 rounded-l-lg transition duration-500 ease-in-out overflow-y-auto">
        <h1 className="my-5 text-center text-3xl font-bold">
            <span className="text-red-500">Package</span> Pricing
          </h1>
          <form onSubmit={handleFormSubmit} className="max-w-md mx-auto">
            <div className="mb-4">
              <label htmlFor="course" className="block mb-1">
                Course:
              </label>
              <select
                name="course"
                id="course"
                value={course}
                onChange={(e) => setCourse(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option>select course</option>
                <option>Complete Acting Course</option>
                <option>Acting</option>
                <option>Kids Acting</option>
                <option>Film Acting Course</option>
                <option>Child Acting</option>
                <option>Mix Martial Arts</option>
                <option>Photoshoot</option>
                <option>Dancing</option>
                <option>Modelling</option>
                <option>Grooming</option>
                <option>Singing</option>
                <option>Yoga</option>
                <option>Zumba</option>
               
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="package-type" className="block mb-1">
                Package Type:
              </label>
              <select
                name="package-type"
                id="package-type"
                value={packageType}
                onChange={(e) => setPackageType(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              >
                  <option>select Tenure</option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Half yearly">Half Yearly</option>
                <option value="yearly">Yearly</option>
                <option value="5 year">5 Year</option>
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="amount" className="block mb-1">
                Amount:
              </label>
              <input
                type="number"
                name="amount"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <button
              type="submit"
              className="px-4 py-2 bg-red-500 text-white rounded"
            >
              Update Package
            </button>
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          </form>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Pricing;
