import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import Nav from "./Nav";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
export default function KKInstitute() {
  return (
    <div>
      <Nav />
      <Helmet>
        <title>About The KK Institute-Best Acting Institute in Ahmedabad</title>
        <meta
          name="description"
          content="We are one of the leading and emerging acting institutes in ahmadabad"
        />
      </Helmet>
      {/* <!-- Header --> */}

      <div className="flex flex-wrap justify-center -mx-4">
        <div className="w-full lg:w-2/3 px-4">
          <h1 className="my-5 text-center text-3xl font-bold">
            <span className="text-red-500">About</span> The KK Institute
          </h1>
          <div className=" -mx-4 mb-8">
            <div className="w-full my-5 md:w-full h-96 px-4">
              <img
                src="https://media.istockphoto.com/id/1360092910/photo/words-with-about-us-web-concept-idea.jpg?b=1&s=170667a&w=0&k=20&c=8ER5GnlVth7iPBW1_5rVcvaXOmpDWdkPfRqcqgnS-mc="
                alt="Image 1"
                className="w-full object-cover h-96 rounded-lg"
              />
            </div>
            {/* <div className="w-full my-5 md:w-1/2 px-4">
        <img src="https://media.istockphoto.com/id/1426338050/photo/collaboration-of-business-people-in-meeting-for-project-management-planning-an-success-goal.jpg?b=1&s=170667a&w=0&k=20&c=e4bDwAWT8IIgI5oUZAdLXwPZyPjZ9j-p7IX-oGLYbWk=" alt="Image 2" className="w-full object-cover h-64 rounded-lg"/>
      </div> */}
          </div>
          <p className="mb-8 text-lg leading-relaxed text-center text-gray-600">
            We are one of the leading and emerging acting institutes in
            ahmedabad, owned by Production House NETRIX ENTERTAINMENT PVT. LTD.
            NETRIX ENTERTAINMENT PVT. LTD. is Registered with IMPPA (India
            Motion Pictures Producers Association, Mumbai).
          </p>

          <p className="mb-8 text-lg leading-relaxed text-center text-gray-600">
            Our dedicated team of experienced instructors brings a wealth of
            knowledge and practical experience that students receive a
            well-rounded education encompassing acting techniques, script
            analysis, improvisation and more. We provide a supportive and
            collaborative environment, fostering creativity and self-expression.
            Our state-of-the-art facilities and industry connections offer
            students opportunities to engage with the professional world of
            theatre and film. Whether you're an aspiring actor looking to refine
            your skills or someone seeking personal growth through the
            performing arts, our Acting Institute is the place where dreams take
            centre stage, and talent finds its spotlight
          </p>
          <p className="mb-8 text-lg leading-relaxed text-center text-gray-600">
            Our institute offers complete training in film acting for adults and
            children. We aim to provide quality education with highly qualified
            and experienced faculty. Our campus has individual rooms for theory,
            practical, and audio-video sessions, and we also have a hostel
            facility for outsider students.
          </p>
          <div className="flex flex-wrap -mx-4"></div>
          <p className="mb-8 text-lg leading-relaxed text-center text-gray-600">
            As far as placement is concerned, we have our own production house
            that will give experience to our students in films, albums, short
            films, and documentaries. Join our acting institute today and enter
            the glamorous world of the film industry.
          </p>
          <p className="mb-8 text-lg leading-relaxed text-center text-gray-600">
            THE KK INSTITUTE of acting was established in ahmedabad by Kamlesh
            Kushwaha as a professional acting school for actors to learn the
            craft using modern techniques and methods. Our institute is
            considered the most preferred acting school for aspiring actors in
            Gujarat. Our faculty is handpicked and has extensive experience and
            knowledge of the craft and are adept at bringing out the performer
            within.
          </p>
          <p className="mb-8 text-lg leading-relaxed text-center text-gray-600">
            The KK Institute is the best acting school that for kids and is the
            epitome of excellence in the field of acting schools
          </p>
        </div>
      </div>

      {/*  */}
      <Footer />
    </div>
  );
}
